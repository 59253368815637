/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TextBoxOption from "./TextBoxOption";
import RadioButtonOption from "./RadioButtonOption";
import DropdownOption from "./DropdownOption";
import CheckBoxOption from "./CheckBoxOption";
import axios from "axios";

const options = [
  { label: "Radio", value: "radio" },
  { label: "Checkbox", value: "checkbox" },
  { label: "Dropdown", value: "dropdown" },
  { label: "TextBox", value: "textbox" },
];

function QuestionModal(props) {
  const [required, setRequired] = useState(props.data.required.value);
  const [visible, setVisible] = useState(props.data.visible.value);
  const [rateBearing, setRateBearing] = useState(props.data.rateBearing.value);
  const [setValue, setSetValue] = useState(props.data.setValue.value);
  const [enabled, setEnabled] = useState(props.data.enabled.value);
  const [answerOption, setAnswerOption] = useState(props.data.answer.type);
  const [label, setLabel] = useState(props.data.label);
  const [dataBinding, setDataBinding] = useState(props.data.dataBinding);
  const [defaultVal, setDefaultVal] = useState(props.data.default);
  const [visibleDependency, setVisibleDependency] = useState(
    props.data.visible.dependency.ref
  );
  const [requiredDependency, setRequiredDependency] = useState(
    props.data.required.dependency.ref
  );
  const [setValueDependency, setSetValueDependency] = useState(
    props.data.setValue.dependency.ref
  );
  const [enabledDependency, setEnabledDependency] = useState(
    props.data.enabled.dependency.ref
  );
  const [tooltip, setTooltip] = useState(props.data.tooltip);
  const [labelSubscript, setLabelSubscript] = useState(
    props.data.labelSubscript
  );

  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  //const [rules, setRules] = useState(props.data.rules);
  useEffect(() => {
    //let myProjects = localStorage.getItem("myProjects") || "[]";
    //if (myProjects) {
      //myProjects = JSON.parse(myProjects);
      axios
        .get(`${BASE_URL}/api/rules/business/rules`)
        .then((response) => {
          console.log(response.data);
          props.data.rules = response.data;
        })
        .catch((ex) => {
          console.log(ex);
        });
    //}
  }, []);

  const onChangeDefault = (event) => {
    props.data.default = event.target.value;
    setDefaultVal(event.target.value);
  };
  const onChangeRequired = () => {
    props.data.required.value = required ? false : true;
    setRequired(required ? false : true);
  };

  const onChangeVisible = () => {
    props.data.visible.value = visible ? false : true;
    setVisible(visible ? false : true);
  };

  const onChangeRateBearing = () => {
    props.data.rateBearing.value = rateBearing ? false : true;
    setRateBearing(rateBearing ? false : true);
  };

  const onChangeEnabled = () => {
    props.data.enabled.value = enabled ? false : true;
    setEnabled(enabled ? false : true);
  };

  const onChangeSetValue = (event) => {
    props.data.setValue.value = event.target.value;
    setSetValue(event.target.value);
  };

  const onChangeAnswerOption = (event) => {
    props.data.answer.type = event.target.value;
    setAnswerOption(event.target.value);
  };

  const onChangeDataBinding = (event) => {
    props.data.dataBinding = event.target.value;
    setDataBinding(event.target.value);
  };

  const onChangeLabel = (event) => {
    props.data.label = event.target.value;
    setLabel(event.target.value);
  };
  const onChangeLabelSubscript = (event) => {
    props.data.labelSubscript = event.target.value;
    setLabelSubscript(event.target.value);
  };
  const onChangeTooltip = (event) => {
    props.data.tooltip = event.target.value;
    setTooltip(event.target.value);
  };

  const onChangeVisibleDependency = (event) => {
    props.data.visible.dependency =
      props.data.rules.find((r) => r.ref === event.target.value) || null;
    setVisibleDependency(event.target.value);
  };

  const onChangeRequiredDependency = (event) => {
    props.data.required.dependency =
      props.data.rules.find((r) => r.ref === event.target.value) || null;
    setRequiredDependency(event.target.value);
  };
  const onChangeEnabledDependency = (event) => {
    props.data.enabled.dependency =
      props.data.rules.find((r) => r.ref === event.target.value) || null;
    setEnabledDependency(event.target.value);
  };

  const onChangeSetValueDependency = (event) => {
    props.data.setValue.dependency =
      props.data.rules.find((r) => r.ref === event.target.value) || null;
    setSetValueDependency(event.target.value);
  };

  return (
    <>
      <Modal size="lg" fullscreen show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Question Attributes...</Modal.Title>
        </Modal.Header>
        <Modal.Body style={props.data.remote ? {pointerEvents: "none", opacity: "0.8", fontSize: "0.8rem"} : { fontSize: "0.8rem" }}>
          <Row style={{ borderBottom: "solid 1px" }}>
            <Col sm="2">Data Binding</Col>
            <Col sm="4">
              <Form.Control
                size="sm"
                style={{ marginBottom: "3px" }}
                type="text"
                placeholder="Unique Identifier..."
                value={dataBinding}
                onChange={(event) => onChangeDataBinding(event)}
              />
            </Col>
            <Col sm="2">Label</Col>
            <Col sm="4">
              <Form.Control
                size="sm"
                style={{ marginBottom: "3px" }}
                type="text"
                placeholder="Question Label..."
                value={label}
                onChange={(event) => onChangeLabel(event)}
              />
            </Col>
          </Row>
          <Row className="mt-2" style={{ borderBottom: "solid 1px" }}>
            <Col sm="2">Tooltip</Col>
            <Col sm="4">
              <Form.Control
                as="textarea"
                rows={2}
                value={tooltip}
                onChange={(event) => onChangeTooltip(event)}
                size="sm"
                style={{ marginBottom: "3px" }}
              />
            </Col>
            <Col sm="2">Label Subscript</Col>
            <Col sm="4">
              <Form.Control
                size="sm"
                style={{ marginBottom: "3px" }}
                as="textarea"
                rows={2}
                value={labelSubscript}
                onChange={(event) => onChangeLabelSubscript(event)}
              />
            </Col>
          </Row>
          <Row className="mt-2" style={{ borderBottom: "solid 1px" }}>
            <Col sm="2">Answer</Col>
            <Col sm="4">
              <Form.Select
                size="sm"
                style={{ marginBottom: "3px" }}
                className="nodrag"
                onChange={(event) => onChangeAnswerOption(event)}
                value={answerOption}
              >
                <option key="select">-- Select --</option>
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col sm="1">Default</Col>
            <Col sm="3">
              <Form.Control
                size="sm"
                style={{ marginBottom: "3px" }}
                type="text"
                placeholder="Default value..."
                value={defaultVal}
                onChange={(event) => onChangeDefault(event)}
              />
            </Col>
            <Col sm="1">Rate Bearing</Col>
            <Col sm="1">
              <Form.Check
                size="sm"
                style={{ marginBottom: "3px" }}
                type="checkbox"
                checked={rateBearing}
                onChange={() => onChangeRateBearing()}
              />
            </Col>
          </Row>
          {answerOption === "textbox" && <TextBoxOption data={props.data} />}
          {answerOption === "radio" && (
            <RadioButtonOption data={props.data} rules={props.data.rules} />
          )}
          {answerOption === "dropdown" && (
            <DropdownOption data={props.data} rules={props.data.rules} />
          )}
          {answerOption === "checkbox" && (
            <CheckBoxOption data={props.data} rules={props.data.rules} />
          )}
          <Row className="mt-2" style={{ borderBottom: "solid 1px" }}>
            <Col sm="3">
              <Row>
                <Col sm="4">Required</Col>
                <Col sm="1">
                  <Form.Check
                    type="checkbox"
                    checked={required}
                    onChange={() => onChangeRequired()}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                {required && (
                  <>
                    <Col sm="4">Condition</Col>
                    <Col sm="8">
                      <Form.Select
                        size="sm"
                        style={{ marginBottom: "3px" }}
                        className="nodrag"
                        value={requiredDependency}
                        onChange={(event) => onChangeRequiredDependency(event)}
                      >
                        <option key="select">-- Select --</option>
                        {props.data.rules &&
                          props.data.rules.map((option) => (
                            <option key={option.ref} value={option.ref}>
                              {option.ref}
                            </option>
                          ))}
                      </Form.Select>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
            <Col sm="3">
              <Row>
                <Col sm="4">Visible</Col>
                <Col sm="1">
                  <Form.Check
                    size="sm"
                    style={{ marginBottom: "3px" }}
                    type="checkbox"
                    checked={visible}
                    onChange={() => onChangeVisible()}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                {!visible && (
                  <>
                    <Col sm="4">Condition</Col>
                    <Col sm="8">
                      <Form.Select
                        size="sm"
                        style={{ marginBottom: "3px" }}
                        className="nodrag"
                        value={visibleDependency}
                        onChange={(event) => onChangeVisibleDependency(event)}
                      >
                        <option key="select">-- Select --</option>
                        {props.data.rules &&
                          props.data.rules.map((option) => (
                            <option key={option.ref} value={option.ref}>
                              {option.ref}
                            </option>
                          ))}
                      </Form.Select>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
            <Col sm="3">
              <Row>
                <Col sm="4">Set Value</Col>
                <Col sm="8">
                  <Form.Control
                    size="sm"
                    style={{ marginBottom: "3px" }}
                    type="text"
                    placeholder="Value..."
                    value={setValue}
                    onChange={(event) => onChangeSetValue(event)}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                {setValue && (
                  <>
                    <Col sm="4">Condition</Col>
                    <Col sm="8">
                      <Form.Select
                        size="sm"
                        style={{ marginBottom: "3px" }}
                        className="nodrag"
                        value={setValueDependency}
                        onChange={(event) => onChangeSetValueDependency(event)}
                      >
                        <option key="select">-- Select --</option>
                        {props.data.rules &&
                          props.data.rules.map((option) => (
                            <option key={option.ref} value={option.ref}>
                              {option.ref}
                            </option>
                          ))}
                      </Form.Select>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
            <Col sm="3">
              <Row>
                <Col sm="4">Enabled</Col>
                <Col sm="1">
                  <Form.Check
                    type="checkbox"
                    checked={enabled}
                    onChange={() => onChangeEnabled()}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                {enabled && (
                  <>
                    <Col sm="4">Condition</Col>
                    <Col sm="8">
                      <Form.Select
                        size="sm"
                        style={{ marginBottom: "3px" }}
                        className="nodrag"
                        value={enabledDependency}
                        onChange={(event) => onChangeEnabledDependency(event)}
                      >
                        <option key="select">-- Select --</option>
                        {props.data.rules &&
                          props.data.rules.map((option) => (
                            <option key={option.ref} value={option.ref}>
                              {option.ref}
                            </option>
                          ))}
                      </Form.Select>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>

          <Row className="mt-2"></Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={props.handleClose} disabled={props.data.remote}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default QuestionModal;
