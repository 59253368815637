import React from "react";
import Form from 'react-bootstrap/Form';

function SpanToInputElementMaker(props) {
  return (
    <span>
      {
        props.showInputEle ? (
          <Form.Control type="text" size="sm"
          disabled={props.remote}
            name={props.name}
            value={props.value}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            autoFocus
          />
        ) : (
          <span 
            onDoubleClick={props.remote ? () => {} : props.handleDoubleClick}
            className={props.className}
          >
            {props.spanvalue}
          </span>
        )
      }
    </span>
  );
}

export default SpanToInputElementMaker;