import React from "react";
import SelectVerbalField from "./forms/select-verbal-field";

function SpanToSelectElementMaker(props) {
  return (
    <span>
      {
        props.showInputEle ? (
          <SelectVerbalField 
          remote={props.remote}
            name={props.name}
            options={props.options}
            value={props.value}
            handleOnChange={props.handleChange}
            handleBlur={props.handleBlur}
            width={props.width}
          />
        ) : (
          <span 
            onDoubleClick={props.remote ? () => {} : props.handleDoubleClick }
            className={props.className}
          >
            {props.spanvalue}
          </span>
        )
      }
    </span>
  );
}

export default SpanToSelectElementMaker;