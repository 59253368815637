import { javascript } from "@codemirror/lang-javascript";
import CodeMirror from "@uiw/react-codemirror";
import { Modal } from "react-bootstrap";
const CodeView = (props) => {
    
    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Rule Code</Modal.Title>
                </Modal.Header>
                <Modal.Body className="CodeMirror" style={{ height: "400px" }} ><CodeMirror
                    value={props.code}
                    height="380px"
                    extensions={[javascript({ jsx: true })]}
                /></Modal.Body>
            </Modal>
        </>
    )
}
export default CodeView;