import { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";

const datatypes = [
  { label: "Integer", value: "integer" },
  { label: "String", value: "string" },
];

const TextBoxOption = (props) => {
  const [mask, setMask] = useState(props.data.answer.mask);
  const [length, setLength] = useState(props.data.answer.length);
  const [format, setFormat] = useState(props.data.answer.format);
  const [dataType, setDataType] = useState(props.data.answer.dataType);
  const onChangeMask = (event) => {
    props.data.answer.mask = event.target.value;
    setMask(event.target.value);
  };

  const onChangeFormat = (event) => {
    props.data.answer.format = event.target.value;
    setFormat(event.target.value);
  };

  const onChangeDataType = (event) => {
    props.data.answer.dataType = event.target.value;
    setDataType(event.target.value);
  };

  const onChangeLength = (event) => {
    props.data.answer.length = event.target.value;
    setLength(event.target.value);
  };

  return (
    <>
      <Row style={{ borderBottom: "solid 1px" }}>
        <Col sm="3"></Col>
        <Col sm="9">
          <Row>
            <Col sm="3"><strong>Answer Options:</strong></Col>
            <Col sm="9">
              
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm="3">Mask</Col>
            <Col sm="9">
              <Form.Control
                size="sm"
                type="text"
                placeholder="e.g. date"
                value={mask}
                onChange={(event) => onChangeMask(event)}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm="3">Data Type</Col>
            <Col sm="9">
              <Form.Select
                size="sm"
                className="nodrag"
                onChange={(event) => onChangeDataType(event)}
                value={dataType}
              >
                <option key="select">-- Select --</option>
                {datatypes.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm="3">Max Length</Col>
            <Col sm="9">
              <Form.Control
                size="sm"
                type="number"
                onChange={(event) => onChangeLength(event)}
                value={length}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm="3">Format</Col>
            <Col sm="9">
              <Form.Control
                size="sm"
                style={{marginBottom:"5px"}}
                type="text"
                placeholder="Regex..."
                onChange={(event) => onChangeFormat(event)}
                value={format}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default TextBoxOption;
