import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import InputsForm from "./InputForm";
import FactForm from "./FactForm";
import DecisionTable from "./DecisionTable";
import OutcomesForm from "./OutcomesForm";
import TestRule from "./TestRule";
import CodeEditor from "./ShowCodeEditor";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCodeFork } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const BRMSRuleView = (props) => {
  const [tabview, setTabview] = useState("facts");
  const [name, setName] = useState(props.rule.name);
  const [ruleType, setRuleType] = useState(props.rule.type);
  const [description, setDescription] = useState(props.rule.description);
  const onChangeName = (event) => {
    props.rule.name = event.target.value;
    if (ruleType === "condition") {
      if (props.rule.inputconditions.length > 0) {
        props.rule.inputconditions[0].title = event.target.value;
      } else {
        props.rule.inputconditions = [
          {
            title: event.target.value,
            inputs: [],
            expressions: [],
            expressionGroup: " && ",
          },
        ];
      }
    }
    setName(event.target.value);
  };
  const onChangeType = (val) => {
    props.rule.type = val;
    if (val === "condition") {
      props.rule.inputconditions = [
        {
          title: props.rule.name,
          inputs: [],
          expressions: [],
          expressionGroup: " && ",
        },
      ];
    } else {
      props.rule.inputconditions = [];
    }
    setRuleType(val);
  };
  const onChangeDescription = (event) => {
    props.rule.description = event.target.value;
    setDescription(event.target.value);
  };

  const onChangeTab = (tabname) => {
    setTabview(tabname);
  };

  const showConfirmationBox = (val) => {
    confirmAlert({
      //title: 'Change in Rule Type?',
      message: `Are you sure to change rule type from ${ruleType} to ${val}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => onChangeType(val),
        },
        {
          label: "No",
          onClick: () => console.log("Do not change rule type"),
        },
      ],
    });
  };
  const [businessConditions, setBusinessConditions] = useState([]);
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/api/business/projects/${props.selectedProject}/features/${props.selectedFeature}/conditions`
      )
      .then((response) => {
        console.log(response.data);
        setBusinessConditions(response.data);
      })
      .catch((ex) => {
        console.log(ex);
      });
  }, [])
  return (
    <>
      <Container fluid className="font12">
        {props.rule.origin === "remote" && (
          <Alert variant="info" className="font12">
            This rule's origin is remote, please checkout to edit.{" "}
            <Button variant="outline-secondary" size="sm" onClick={props.onClickCancel}>
            <FontAwesomeIcon
                      icon={faCancel}
                      title="Cancel"
                    />
            </Button>{' '}
            <Button variant="outline-success" size="sm" onClick={props.onClickCheckout}>
            <FontAwesomeIcon
                      icon={faCodeFork}
                      title="Checkout"
                    />
            </Button>
          </Alert>
        )}
        <Row
          className={
            props.rule.origin === "remote"
              ? "disable-all-controls mt-2"
              : "mt-2"
          }
        >
          <Col sm="12">
            <Card className="shadow-lg">
              <Card.Body
                style={{ paddingBottom: "0.2rem", paddingTop: "0.5rem" }}
              >
                <Row>
                  <Col sm="4">
                    <div class="custom-input-wrapper">
                      <label className="custom-label" for="name">
                        Name
                      </label>
                      <input
                        className="custom-input"
                        type="text"
                        placeholder="Rule name..."
                        value={name}
                        onChange={(event) => onChangeName(event)}
                      />
                    </div>
                  </Col>
                  <Col sm="2">
                    <div class="custom-input-wrapper">
                      <Form.Check
                        type="radio"
                        name="rule-type"
                        label="Condition"
                        checked={ruleType === "condition"}
                        onChange={() => showConfirmationBox("condition")}
                      />
                      <Form.Check
                        type="radio"
                        name="rule-type"
                        label="Decision"
                        checked={ruleType === "decision"}
                        onChange={() => showConfirmationBox("decision")}
                      />
                    </div>
                  </Col>
                  <Col sm="6">
                    <div class="custom-input-wrapper">
                      <label className="custom-label" for="description">
                        Description
                      </label>
                      <input
                        className="custom-input"
                        type="text"
                        placeholder="Rule Description..."
                        value={description}
                        onChange={(event) => onChangeDescription(event)}
                      />
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col sm="12">
            <Card className="shadow-lg rule-details">
              <Card.Body>
                <Tabs
                  defaultActiveKey="facts"
                  id="uncontrolled-tab-example"
                  className="myClass"
                  onSelect={(key) => onChangeTab(key)}
                >
                  <Tab
                    eventKey="facts"
                    title="Data Types"
                    className="rule-tab-content"
                  >
                    {tabview === "facts" && (
                      <FormGroup as={Row} className="mb-3" controlId="facts">
                        <Col sm={12}>
                          <FactForm
                            values={props.rule}
                            title="Data Types"
                            id="facts"
                            remote={props.rule.origin === "remote"}
                          />
                        </Col>
                      </FormGroup>
                    )}
                  </Tab>
                  <Tab
                    eventKey="inputs"
                    title="Inputs"
                    className="rule-tab-content"
                  >
                    {tabview === "inputs" && (
                      <FormGroup as={Row} className="mb-3" controlId="inputs">
                        <Col sm={12}>
                          <InputsForm
                            values={props.rule}
                            title="Inputs"
                            id="input"
                            remote={props.rule.origin === "remote"}
                            businessConditions={businessConditions}
                          />
                        </Col>
                      </FormGroup>
                    )}
                  </Tab>
                  {ruleType === "decision" && (
                    <Tab
                      eventKey="outcomes"
                      title="Outputs"
                      className="rule-tab-content"
                    >
                      {tabview === "outcomes" && (
                        <FormGroup
                          as={Row}
                          className="mb-3"
                          controlId="outcomes"
                        >
                          <Col sm={12}>
                            <OutcomesForm
                              remote={props.rule.origin === "remote"}
                              values={props.rule}
                              title="Outcome"
                              id="outcome"
                            />
                          </Col>
                        </FormGroup>
                      )}
                    </Tab>
                  )}
                  {ruleType === "decision" && (
                    <Tab
                      eventKey="decisiontable"
                      title="Decision Table"
                      className="rule-tab-content"
                    >
                      {tabview === "decisiontable" && (
                        <FormGroup
                          as={Row}
                          className="mb-3"
                          controlId="decisiontable"
                        >
                          <Col sm={12}>
                            <DecisionTable
                              remote={props.rule.origin === "remote"}
                              values={props.rule}
                              title="Decision Table"
                              id="decisiontable"
                            />
                          </Col>
                        </FormGroup>
                      )}
                    </Tab>
                  )}
                  {ruleType === "decision" && (
                    <Tab
                      eventKey="testcase"
                      title="Test Cases"
                      className="rule-tab-content"
                    >
                      {tabview === "testcase" && (
                        <FormGroup
                          as={Row}
                          className="mb-3"
                          controlId="testcases"
                        >
                          <Col sm={12}>
                            <TestRule
                              remote={props.rule.origin === "remote"}
                              values={props.rule}
                              title="Test Cases"
                              id="testcases"
                            />
                          </Col>
                        </FormGroup>
                      )}
                    </Tab>
                  )}
                  {ruleType === "decision" && (
                    <Tab
                      eventKey="code"
                      title="Code"
                      className="rule-tab-content"
                    >
                      {tabview === "code" && (
                        <FormGroup as={Row} className="mb-3" controlId="code">
                          <Col sm={12}>
                            {
                              <CodeEditor
                                remote={props.rule.origin === "remote"}
                                values={props.rule}
                                title="Code"
                                id="code"
                              />
                            }
                          </Col>
                        </FormGroup>
                      )}
                    </Tab>
                  )}
                </Tabs>
                <div>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={props.onClickCancel}
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    variant="success"
                    size="sm"
                    onClick={props.onSaveRule}
                    disabled={props.rule.origin === "remote"}
                  >
                    Save
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default BRMSRuleView;
