/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCaretDown,
  faCaretUp,
  faGlobe,
  faHome,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import AddProject from "./AddProject";
import axios from "axios";

const RuleViewer = (props) => {
  //const [loading, setLoading] = useState(true);
  //const [projects, setProjects] = useState([]);
  const [showAddProject, setShowAddProject] = useState(false);
  const [showAddFeature, setShowAddFeature] = useState(false);
  //const [selectedProject, setSelectedProject] = useState(props.selectedProject);
  //const [selectedFeature, setSelectedFeature] = useState(props.selectedFeature);
  //const [selectedRule, setSelectedRule] = useState(props.selectedRule);

  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  
  const onBlurFeature = (event, prj, pId) => {
    axios
        .post(`${BASE_URL}/api/business/projects/${pId}/features`, {
          name: event.target.value,
        })
        .then((response) => {
          console.log(response.data);
          props.updateProjects(pId, response.data);
          setShowAddFeature(false);
        })
        .catch((ex) => {
          console.log(ex);
        });
    /*let myProjects = localStorage.getItem("myProjects") || "[]";
    if (myProjects) {
      myProjects = JSON.parse(myProjects);
      let currProject = myProjects.find((p) => p.name === prj) || null;
      let featureExists =
        currProject.features.find((p) => p.name === event.target.value) || null;
      if (!featureExists) {
        currProject.features.push({ name: event.target.value, rules: [] });
      }
      setShowAddFeature(false);
      props.setProjects(myProjects);
      localStorage.setItem("myProjects", JSON.stringify(myProjects));
    } else {
      /*axios
        .post(`${BASE_URL}/api/rules/business/projects/${prj}/features`, {
          name: event.target.value,
        })
        .then((response) => {
          props.setProjects(response.data);
          setShowAddFeature(false);
        })
        .catch((ex) => {
          console.log(ex);
        });*/
    //}
  };
  const onSelectRule = (prj, feature, ruleName, pId, fId, url) => {
    props.setSelectedProject(pId);
    props.setSelectedFeature(fId);
    props.setSelectedRule(ruleName);
    props.onSelectRule(pId, fId, ruleName, url);
  };
  return (
    <>
      {showAddProject && (
        <AddProject
          show={showAddProject}
          setProjects={props.setProjects}
          handleClose={() => setShowAddProject(false)}
        />
      )}
      <Row>
        <Col>
          <span>PROJECTS</span>
          <FontAwesomeIcon
            icon={faPlus}
            style={{ float: "right" }}
            onClick={() => setShowAddProject(true)}
          />
          <hr />
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          {props.projects &&
            props.projects.map((p, pi) => {
              return (
                <>
                  <Row>
                    <Col sm="1">
                      {props.selectedProject === p._id ? (
                        <FontAwesomeIcon
                          icon={faCaretUp}
                          onClick={() => props.setSelectedProject("")}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCaretDown}
                          onClick={() => props.setSelectedProject(p._id)}
                        />
                      )}
                    </Col>
                    <Col sm="11">
                      <span>{p.name}</span>
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{ float: "right", paddingLeft: "5px" }}
                        onClick={() => setShowAddFeature(p._id)}
                      />
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={{ float: "right" }}
                        onClick={() => props.onDeleteProject(p._id, p.name)}
                      />
                      <hr />
                    </Col>
                  </Row>
                  {showAddFeature === p._id && (
                    <Row>
                      <Col sm="3">
                        <Form.Label>Name</Form.Label>
                      </Col>
                      <Col sm="9">
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="feature name..."
                          onBlur={(event) => onBlurFeature(event, p.name, p._id)}
                        />
                      </Col>
                    </Row>
                  )}
                  {props.selectedProject === p._id &&
                    p.features &&
                    p.features.map((f, fi) => {
                      return (
                        <>
                          <Row>
                            <Col sm="1"></Col>
                            <Col sm="1">
                              {props.selectedProject === p._id &&
                              props.selectedFeature === f._id ? (
                                <FontAwesomeIcon
                                  icon={faCaretUp}
                                  onClick={() => props.setSelectedFeature("")}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faCaretDown}
                                  onClick={() =>
                                    props.setSelectedFeature(f._id)
                                  }
                                />
                              )}
                            </Col>
                            <Col sm="10">
                              <span>{f.name}</span>
                              <FontAwesomeIcon
                                icon={faPlus}
                                style={{ float: "right", paddingLeft: "5px" }}
                                onClick={() =>
                                  props.onClickAddRule(p._id, p.name, f._id, f.name)
                                }
                              />
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                style={{ float: "right" }}
                                onClick={() =>
                                  props.onDeleteFeature(p._id, f._id, f.name)
                                }
                              />
                              <hr />
                            </Col>
                          </Row>
                          {props.selectedFeature === f._id &&
                            f.rules &&
                            f.rules.map((r, ri) => {
                              return (
                                <>
                                  <Row>
                                    <Col sm="3">
                                    <FontAwesomeIcon
                                        icon={
                                          r.origin === "remote"
                                            ? faGlobe
                                            : faHome
                                        }
                                        style={{ float: "right" }}
                                        title={r.origin === "remote" ? 'remote' : 'local'}
                                        color="blue"
                                      />
                                    </Col>
                                    {/*<Col sm="1">
                                      {props.selectedProject === p.name &&
                                      props.selectedFeature === f.name &&
                                      props.selectedRule === r.id ? (
                                        <FontAwesomeIcon
                                          icon={faCaretUp}
                                          onClick={() =>
                                            props.setSelectedRule("")
                                          }
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          icon={faCaretDown}
                                          onClick={() =>
                                            props.setSelectedRule(r.id)
                                          }
                                        />
                                      )}
                                        </Col>*/}
                                    <Col
                                      sm="9"
                                      onClick={() =>
                                        onSelectRule(p.name, f.name, r._id, p._id, f._id, p.url)
                                      }
                                    >
                                      <span>{r.name}</span>
                                      <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        style={{ float: "right" }}
                                        onClick={() =>
                                          props.onDelete(p._id, f._id, r._id, r.name)
                                        }
                                      />
                                      <hr />
                                    </Col>
                                  </Row>
                                  {/*props.selectedRule === r.id && (
                                    <Row>
                                      <Col sm="3"></Col>
                                      <Col sm="9">
                                        {r.inputconditions &&
                                          r.inputconditions.map((ic, ric) => {
                                            return (
                                              <Row>
                                                <Col sm="12">
                                                  <span>{ic.title}</span>

                                                  <hr />
                                                </Col>
                                              </Row>
                                            );
                                          })}
                                      </Col>
                                    </Row>
                                        )*/}
                                </>
                              );
                            })}
                        </>
                      );
                    })}
                </>
              );
            })}
        </Col>
      </Row>
    </>
  );
};
export default RuleViewer;
