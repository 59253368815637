import React from "react";
import Form from 'react-bootstrap/Form';

const SelectVerbalField = (props) => {
    //console.log(props);
    return (
        <> 
            <Form.Control as="select" name={`${props.name}`} size="sm" style={{width: `${props.width}`}}
                disabled={props.remote}
                onChange={props.handleOnChange}
                value={props.value}
                onBlur={props.handleBlur} autoFocus>
                    <option>Select...</option>
                    
                {props.options && props.options.map((o, i) => {
                    return typeof o === 'object' ? (<option value={o.name}>{o.verbal}</option>) : (<option value={o}>{o}</option>)
                })}
            </Form.Control>
           
        </>
    )
}

export default SelectVerbalField;

