
import './beautiful.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Editor from './components/Editor';
import Main from './components/Main'
import ProfileImage from './components/login/ProfileImage';
import { Button, Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import { useEffect, useState } from 'react';
import '@aws-amplify/ui-react/styles.css';
import BRMSMain from './components/brms/Main';
import Settings from './components/Settings';
import { withAuthenticator } from '@aws-amplify/ui-react';

function App() {
  const [user, setUser] = useState(null);
  const [ruleEngineView, setRuleEngineView] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  useEffect(() => {
    async function currentAuthenticatedUser() {
      try {
        const { username, userId } = await getCurrentUser();
        console.log(`The username: ${username}`);
        console.log(`The userId: ${userId}`);
        setUser(username);
      } catch (err) {
        console.log(err);
      }
    }
    currentAuthenticatedUser();
  }, []);

  async function handleSignOut() {
    try {
      await signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  const handleLogout = (props) => {
    handleSignOut();
  }

  const handleSettings = () => {
    setShowSettings(true);
  }

  const closeShowSettings = () => {
    setShowSettings(false);
  }

  const view = (viewType) => {
    if (viewType === 'PRESENTATION') {
      return <Editor />
    } else if (viewType === 'BRMS') {
      return <BRMSMain />
    } else {
      return <Main setView={setRuleEngineView} />
    }
  }

  return (
    <>
      {showSettings && <Settings show={showSettings} handleClose={closeShowSettings} />}
      <Router>
        <header>
          <Navbar style={{ padding: "0" }}>
            <Container fluid >
              <Navbar.Brand href="/" ><img src="/Logo.png" alt=""></img>
                <span style={{ fontSize: "24px", color: "#fff", fontFamily: "Graphik", fontWeight: "400" }}>rules
                  <span style={{ fontWeight: "600" }}>Weaver</span></span>
              </Navbar.Brand>
              <Nav className="justify-content-end" >
                <Nav.Item href="#home" style={{ color: "white" }} className="middle-align">
                  {ruleEngineView === 'PRESENTATION' && <Button onClick={() => setRuleEngineView('BRMS')}>Business Rules</Button>}
                  {ruleEngineView === 'BRMS' && <Button onClick={() => setRuleEngineView('PRESENTATION')}>Presentation Rules</Button>}
                </Nav.Item>
                <NavDropdown title={<ProfileImage />} id="basic-nav-dropdown" drop='start' style={{ top: "1" }} >
                  <NavDropdown.Item href="#action/3.1" className='profileblock'>
                    <ProfileImage />
                    <div className='profilename'> {user ? user : 'John Doe'}</div>
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => handleSettings()}>
                    <FontAwesomeIcon icon={faCog} />{' '} Settings
                  </NavDropdown.Item>

                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => handleLogout()}>
                    <FontAwesomeIcon icon={faSignOut} />{' '}Sign Out
                  </NavDropdown.Item>
                </NavDropdown>

              </Nav>
            </Container>
          </Navbar>
        </header>
        <Routes>
          <Route path="/" element={view(ruleEngineView)} />

        </Routes>
        <div className="footer">
          <div className='footer-text'>Copyright © 2024 Accenture. All Rights Reserved. Accenture Confidential.</div>
        </div>
      </Router>
    </>

  );


}

export default withAuthenticator(App, {
  hideSignUp: true,
});
