/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import helper from "../helper";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import LoadingSpinner from "../loading-spinner";

const CommitSource = (props) => {
  const [gitToken, setGitToken] = useState('');
  const [commitMessage, setCommitMessage] = useState('my commit message');
  const [showAlert, setShowAlert] = useState(false);
  const [repositoryURL, setRepositoryURL] = useState("");
  const [loading, setLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    let settings = localStorage.getItem('settings');
    if(settings) {
      settings = JSON.parse(settings);
      setGitToken(settings.token);
    }
    let brmsProjects = localStorage.getItem('myProjects') || '[]';
    if (brmsProjects) {
      brmsProjects = JSON.parse(brmsProjects);
      let selectedProject = brmsProjects.find(p => p.name === props.project) || null;
      if (selectedProject) {
        setRepositoryURL(selectedProject.url);
      }
    }
  }, [])

  const onChangeGitToken = (event) => {
    setGitToken(event.target.value);
  }

  const showConfirmationBox = (title, message, action) => {
    confirmAlert({
      //title: title,
      message: message,
      buttons: [
        {
          label: "OK",
          onClick: action,
        },
      ],
    });
  };

  const pushCode = (prj, feature, ruleName, r) => {
    setLoading(true);
    
    const codeStr = helper.generate(r);
    if (codeStr) {
      axios
        .post(`${BASE_URL}/api/git/rules/business/push`, {
          'rule': r,
          'code': codeStr,
          'project': prj,
          'feature': feature,
          'url': repositoryURL,
          'token': gitToken,
          'commitMessage': commitMessage,
        })
        .then((response) => {
          setLoading(false);
          props.onCommit();
          showConfirmationBox(
            "Code Deploy",
            "Rule committed successfully!!",
            () => {
              console.log("Rule committed successfully..");
            }
          );
        })
        .catch((ex) => {
          setLoading(false);
          console.log(ex);
          props.onCommit();
          showConfirmationBox(
            "Code Deploy Error",
            "Some error encountered. Try Again.",
            () => {
              console.log("Rule committed successfully..");
            }
          );
        });
    }
  };
  
  const onChangeMessage = (event) => {
    setCommitMessage(event.target.value);
  };

  const onChangeRepositoryURL = (event) => {
    setRepositoryURL(event.target.value);
    let settings = localStorage.getItem("settings") || "";
    if (!settings) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Commit Rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <LoadingSpinner />}
          {showAlert && (
            <Alert variant="danger">
              Please provide git credentials in settings.
            </Alert>
          )}
          <Row className="mt-1 font12">
            <Col sm="12">
              <strong>
                {props.project} / {props.feature} / {props.ruleName}
              </strong>
            </Col>
          </Row>
          <Row className="mt-3 font12">
            <Col sm="12">
              <div class="custom-input-wrapper">
                <label className="custom-label" for="user">
                  Repository URL
                </label>
                <input
                  className="custom-input"
                  type="text"
                  placeholder="Git Repository URL"
                  value={repositoryURL}
                  onChange={(event) => onChangeRepositoryURL(event)}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3 font12">
            <Col sm="12">
              <div class="custom-input-wrapper">
                <label className="custom-label" for="user">
                  Git Token
                </label>
                <input
                  className="custom-input"
                  type="password"
                  placeholder="Git Token"
                  value={gitToken}
                  onChange={(event) => onChangeGitToken(event)}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3 font12">
            <Col sm="12">
              <div class="custom-input-wrapper">
                <label className="custom-label" for="user">
                  Commit Message
                </label>
                <input
                  className="custom-input"
                  type="text"
                  placeholder="Commit Message"
                  value={commitMessage}
                  onChange={(event) => onChangeMessage(event)}
                />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={!(repositoryURL && gitToken)}
            onClick={() => {pushCode(props.project, props.feature, props.ruleName, props.rule);}}
          >
            Commit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CommitSource;
