import { Button, Modal } from "react-bootstrap";
const ConfirmationModal = (props) => {

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Please confirm...</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <span>Existing code will be replaced..</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={() => props.handleAnswer('Yes')}>Yes</Button>{ }
                    <Button variant="secondary" onClick={() => props.handleAnswer('No')}>No</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ConfirmationModal;