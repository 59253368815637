import axios from "axios";
import { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import AutoCompleteTextbox from "../forms/AutoCompleteTextbox";
import LoadingSpinner from "../loading-spinner";

const AddProject = (props) => {
  const [project, setProject] = useState("");
  const [feature, setFeature] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [repositoryURL, setRepositoryURL] = useState("");
  const [repositoryPath, setRepositoryPath] = useState("");
  const [checkout, setCheckout] = useState(false);
  const [createFeatureBranch, setCreateFeatureBranch] = useState(false);
  const [branches, setBranches] = useState([{ value: "main", label: "main" }]);
  const [loading, setLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const onChangeRepositoryPath = (event) => {
    setRepositoryPath(event.target.value);
  };
  const onChangeCreateFeatureBranch = () => {
    setCreateFeatureBranch(createFeatureBranch ? false : true);
  };

  const onChangeCheckout = () => {
    setCheckout(checkout ? false : true);
  };
  const onSave = () => {
    setLoading(true);
    let settings = localStorage.getItem("settings") || "";
    if (settings) {
      settings = JSON.parse(settings);
    }
    axios
      .post(`${BASE_URL}/api/git/business/rules`, {
        project: project,
        feature: feature,
        token: settings ? settings.token : "",
        url: repositoryURL,
        path: repositoryPath,
        createFeature: createFeatureBranch,
        checkout: checkout,
      })
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        props.setProjects(response.data);
        props.handleClose();
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
        props.handleClose();
      });
  };

  const onChangeProject = (event) => {
    setProject(event.target.value);
  };

  const onChangeFeature = (val) => {
    setFeature(val);
  };

  const onChangeRepositoryURL = (event) => {
    setRepositoryURL(event.target.value);
    let settings = localStorage.getItem("settings") || "";
    if (!settings) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      if (event.target.value) {
        setLoading(true);
        axios
          .post(`${BASE_URL}/api/git/repo/details`, {
            token: settings.token,
            url: event.target.value,
          })
          .then((response) => {
            setLoading(false);
            console.log(response.data);
            if (!project) {
              setProject(response.data.project);
            }
            if (!feature) {
              setFeature(response.data.feature);
            }
            setBranches(response.data.branches);
          })
          .catch((ex) => {
            console.log(ex);
          });
      }
    }
  };
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Project</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showAlert && (
            <Alert variant="danger">
              Please provide git credentials in settings.
            </Alert>
          )}
          {loading && <LoadingSpinner />}
          <Row className="mt-1 font12">
            <Col sm="12">
              <div class="custom-input-wrapper">
                <label className="custom-label" for="user">
                  Repository URL (optional)
                </label>
                <input
                  className="custom-input"
                  type="text"
                  placeholder="Git Repository URL"
                  onBlur={(event) => onChangeRepositoryURL(event)}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3 font12">
            <Col sm="12">
              <div class="custom-input-wrapper">
                <label className="custom-label" for="user">
                  Path (optional)
                </label>
                <input
                  className="custom-input"
                  type="text"
                  placeholder="Folder path if rules are not at root path"
                  onChange={(event) => onChangeRepositoryPath(event)}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-3 font12">
            <Col sm="6">
              <div class="custom-input-wrapper">
                <label className="custom-label" for="pname">
                  Project Name*
                </label>
                <input
                  className="custom-input"
                  type="text"
                  placeholder="Project/Repository Name..."
                  value={project}
                  onChange={(event) => onChangeProject(event)}
                />
              </div>
            </Col>
            <Col sm="6">
              <div class="custom-input-wrapper">
                <label className="custom-label" for="fname">
                  Feature Name
                </label>
                <AutoCompleteTextbox
                  options={branches}
                  value={feature}
                  handleChange={onChangeFeature}
                />
              </div>
            </Col>
          </Row>

          {repositoryURL && (
            <Row className="mt-3 font12">
              <Col sm="6">
                <Form.Check // prettier-ignore
                  type={"checkbox"}
                  id={"checkout"}
                  label={"Checkout repository"}
                  checked={checkout}
                  onChange={() => onChangeCheckout()}
                />
              </Col>
              <Col sm="6">
                <Form.Check // prettier-ignore
                  type={"checkbox"}
                  id={"checkout"}
                  label={"Create feature branch"}
                  checked={createFeatureBranch}
                  onChange={() => onChangeCreateFeatureBranch()}
                />
              </Col>
            </Row>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={!(project && feature)}
            onClick={() => onSave()}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AddProject;
