import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import {  Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import AttributeForm from "../AttributeForm";
import dataTypes from '../../data/operator.json';
import SelectField from "../forms/select-field";

const FactForm = (props) => {

    const [facts, setFacts] = useState(props.values.facts);
    //const [attributes, setAttributes] = useState([]);
    console.log(facts);

    const onNameChange = (event, index) => {
        let temp = [...facts];
        temp[index].name = event.target.value;
        props.values.facts[index].name = event.target.value;
        setFacts(temp);
    }

    /*const onArrayChange = (event, index) => {
        props.values.facts[index].array = props.values.facts[index].array ? false : true;
        setFacts(props.values.facts);
    }*/

    const onTypeChange = (event, index) => {
        let temp = [...facts];
        temp[index].type = event.target.value;
        props.values.facts[index].type = event.target.value;
        setFacts(temp);
    }

    const addItem = () => {
        const newList = [...props.values.facts, { 'name': '', 'type': '', array:false, 'attributes': [] }];
        props.values.facts = newList;
        setFacts(newList);
        //setAttributes([]);
    }



    const addItemAttribute = index => () => {
        let temp = [...facts];
        temp.filter((_, i) => index === i).map(e => {
            e.attributes.push({ 'name': '', 'type': '', array:false });
            return "";
        })
        props.values.facts = temp;
        setFacts(temp);
    }

    const deleteItem = index => () => {
        let temp = [...facts];
        temp = temp.filter((_, i) => index !== i);
        props.values.facts = temp;
        setFacts(temp);
    }

    const attributesTypes = Object.keys(dataTypes);
    const objectFacts = props.values.facts && props.values.facts.filter((e) => e.type === 'Object' && e.name !== '');
    if (objectFacts && objectFacts.length > 0) {
        objectFacts.forEach(e => attributesTypes.push(e.name));
    }

    return (
        <>
            <div style={{ position: 'relative', padding: '12px', fontSize:"12px" }} className="border">
                <Row className="border-bottom border-dark">
                    <Col sm={10}><span>{props.title}</span></Col>
                    <Col sm={2}>
                        <Button onClick={addItem} variant="link" disabled={props.remote}>
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </Col>
                </Row>
                {props.values.facts && props.values.facts.map((f, i) => {

                    return (
                        <div key={i} style={{ position: 'relative', paddingTop: '12px' }}>
                            <div>
                                <Row>
                                <Col sm={1}>{i+1}.</Col>
                                    <Col sm={4}>
                                        <Form.Control type="text" name={`${props.id}[${i}].name`} value={f.name} disabled={props.remote}
                                            placeholder={`Fact Name`} size="sm" onChange={(event) => onNameChange(event, i)} />
                                    </Col>
                                    
                                    <Col sm={3}>
                                        <SelectField name={`${props.id}[${i}].type`} remote={props.remote} options={attributesTypes} handleOnChange={(event) => onTypeChange(event, i)} value={f.type} />
                                    </Col>
                                    <Col sm={1}>
                                        <ButtonGroup>
                                            <Button variant="link" onClick={deleteItem(i)} disabled={props.remote}>
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </Button>
                                            {props.values.facts[i] && props.values.facts[i].type === 'Object'
                                                &&
                                                <Button onClick={addItemAttribute(i)} variant="link" disabled={props.remote}>
                                                    <FontAwesomeIcon icon={faPlus} />
                                                </Button>
                                            }
                                        </ButtonGroup>
                                    </Col>
                                    <Col sm={2}></Col>
                                </Row>
                                {props.values.facts[i].attributes.length > 0 &&
                                    <AttributeForm parent={props.values.facts[i]} types={attributesTypes} remote={props.remote}
                                        index={i} id={`${props.id}[${i}].attributes`}
                                    />
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
}

export default FactForm;