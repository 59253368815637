import { javascript } from "@codemirror/lang-javascript";
import CodeMirror from "@uiw/react-codemirror";
import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
const CodeView = (props) => {
    const [code, setCode] = useState(props.code);
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const content = e.target.result;
            setCode(content);
        };
        reader.readAsText(file);
    };
    return (
        <>
            <Modal show={props.show} onHide={props.handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Rule Code</Modal.Title>
                </Modal.Header>
                <Modal.Body className="CodeMirror" style={{ height: "400px" }} ><CodeMirror
                    value={code}
                    height="380px"
                    extensions={[javascript({ jsx: true })]}
                    onChange={(editor, metadata, value) => {
                        setCode(editor);
                    }}
                /></Modal.Body>
                <Modal.Footer style={{padding:"0"}}>
                    <Row style={{marginTop:"0", marginBottom:"0"}}>
                        <Col sm="8">
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Control type="file" onChange={handleFileUpload} />
                            </Form.Group>
                        </Col>
                        <Col sm="4">
                            <Button variant="primary" onClick={() => props.onChangeCode(code)}>Save Changes</Button>
                        </Col>
                    </Row>


                </Modal.Footer>
            </Modal>
        </>
    )
}
export default CodeView;