import { Col, Row } from "react-bootstrap";
import SideView from "./SideView";
import EditorView from "./EditorView";

const Editor = (props) => {
    return (
        <Row className='wrapper'>
            <Col sm="1" className='col-left' style={{ textAlign: "center", padding: "0" }}>
                <SideView />
            </Col>

            <Col sm="11" >
                <EditorView />
            </Col>
        </Row>
    )
}
export default Editor;