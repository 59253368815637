import { faCog, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useState } from "react";
import { Handle, Position } from "reactflow";
import QuestionModal from "./QuestionModal";
import { Card, Col, OverlayTrigger, Popover, Row } from "react-bootstrap";

function Question({ id, data }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleColor = (answerType) => {
    switch (answerType) {
      case "dropdown":
        return "#F63F3F";
      case "textbox":
        return "#A7D6A9";
      case "radio":
        return "#1E63E5";
      case "checkbox":
        return "#131010";
      default:
        return "#460073";
    }
  };
  const popover = (
    <Popover id="popover-primary" style={{minWidth:"300px"}}>
      <Popover.Header as="h3" style={{backgroundColor:"#460073", color:"white"}}><span style={{fontSize:"12px"}}>{data.label ? data.label : 'Question'}</span></Popover.Header>
      <Popover.Body>
       <Row style={{fontSize:"12px"}}>
        <Col sm="12">
          Answer: {data.answer.type ? data.answer.type : '-'}
        </Col>
       </Row>
       
      </Popover.Body>
    </Popover>
  );


  return (
    <>
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={popover}
    >
      <Card>
        <Card.Body className="custom-node__header">
          <Card.Title style={{ marginBottom: 0 }}>
            <Row>
              <Col
                sm="2"
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  style={{
                    color: handleColor(data.answer.type),
                  }}
                />
              </Col>
              <Col sm="8" style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    fontSize: "10px",
                    color: handleColor(data.answer.type),
                  }}
                >
                  <strong>{data.label ? data.label : 'Question'}</strong>
                </span>
              </Col>
              <Col
                sm="2"
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FontAwesomeIcon
                  icon={faCog}
                  style={{
                    //float: "right",
                    color: handleColor(data.answer.type),
                  }}
                  onClick={() => setShow(true)}
                />
              </Col>
            </Row>
          </Card.Title>
          <Handle type="target" position={Position.Left} id={id} />
        </Card.Body>
      </Card>
      </OverlayTrigger>
      {/*<div className="custom-node__body">
        <span>{data.label}</span>
        <span>{data.required.value}</span>
        
  </div>*/}
      <QuestionModal
        data={data}
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
      />
    </>
  );
}

export default memo(Question);
