import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Row, Col, Button, ButtonGroup, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import dataTypes from "../data/operator.json";
import helper from "./helper";
import Expression from "./Expression";

const InputsConditions = (props) => {
  const [showCondEle, setShowCondEle] = useState(-1);
  const [inputs, setInputs] = useState(props.values.inputs);
  const [showOperatorEle, setShowOperatorEle] = useState(-1);
  const [attributes, setAttributes] = useState([]);

  console.log(inputs);

  /*const onBlurCondition = (index) => {
        setShowCondEle(index);
    }

    const filterExpressionOperator = (cindex, eindex) => {
        let cond = props.values.inputconditions[cindex].expressions[eindex].condition;
        let verbals = objVerbals(props.values.inputconditions[cindex].elmtype);
        let ops = verbals.filter(e => e.name == cond).map(e => e.operators).flatMap(e => e);
        return ops;
    }

    const onChangeExpressionResult = (event, cindex, eindex) => {
        props.values.inputconditions[cindex].expressions[eindex].result = event.target.value;
        props.values.inputconditions[cindex].expressions[eindex].resultdisplay = event.target.value;
        setShowCondEle(-1);
    }


    const onChangeExpressionCondition = (event, factsOptions, cindex, eindex) => {
        factsOptions.filter(e => e.name === event.target.value).forEach(e => {
            props.values.inputconditions[cindex].expressions[eindex].condition = event.target.value;
            props.values.inputconditions[cindex].expressions[eindex].elmtype = e.type;
            props.values.inputconditions[cindex].expressions[eindex].display = e.verbal;
        });
        setShowCondEle(-1);
    }
    const onChangeExpressionOperator = (event, cindex, eindex) => {

        props.values.inputconditions[cindex].expressions[eindex].operator = event.target.value;
        props.values.inputconditions[cindex].expressions[eindex].operatordisplay = event.target.options[event.target.selectedIndex].text;
        setShowCondEle(-1);
        setAttributes([]);
    }
    const onChangeCondition = (event, factsOptions, index) => {
        //props.values.inputconditions[index].operator = event.target.value;
        //props.values.inputconditions[index].operatordisplay = event.target.options[event.target.selectedIndex].text;
        factsOptions.filter(e => e.name === event.target.value).forEach(e => {
            props.values.inputconditions[index].condition = event.target.value;
            props.values.inputconditions[index].elmtype = e.type;
            props.values.inputconditions[index].display = e.verbal;
        });
        props.values.inputconditions[index].operator = '';
        props.values.inputconditions[index].operatordisplay = '';
        props.values.inputconditions[index].result = '';
        props.values.inputconditions[index].resultdisplay = '';
        setShowCondEle(-1);
        setAttributes([]);
        //setOperators({});

    }*/

  const onChangeTitle = (event, index) => {
    //alert('hi');
    props.values.inputconditions[index].title = event.target.value;
    setShowCondEle(-1);
    setAttributes([]);
  };

  /*
        const filterOperators = (factsOptions, index) => {
            let cond = props.values.inputconditions[index].condition;
            const ops = factsOptions.filter(e => e.name === cond).map(e => {
                if (helper.isObject(e.type)) {
                    return e.operators;
                }
                return e.operators;
            }).flatMap(e => e);
            return ops;
        }*/

  const onInputSelectChange = (event, index, index1) => {
    let exist = props.values.inputconditions[index].inputs.filter(
      (e) => e.name === event.target.value
    );

    if (exist && exist.length > 0) {
      props.values.inputconditions[index].inputs = props.values.inputconditions[
        index
      ].inputs.filter((e) => e.name !== event.target.value);
    } else {
      props.values.inputconditions[index].inputs[index1] =
        props.values.inputs.filter((e) => e.name === event.target.value)[0];
    }
    console.log(props.values.inputconditions[index].inputs);
    setShowOperatorEle(-1);
    setAttributes([]);
  };

  const isInputSelected = (val, index) => {
    //alert(val);
    let filteredIns = props.values.inputconditions[index].inputs.filter(
      (e) => e.name === val
    );
    return filteredIns && filteredIns.length > 0;
  };

  const addItem = () => {
    const newList = [
      ...props.values.inputconditions,
      { title: "", inputs: [], expressions: [], expressionGroup: " && " },
    ];
    props.values.inputconditions = newList;
    setInputs(props.values.inputconditions);
  };

  const changeExpressionGroup = (index, event) => {
    props.values.inputconditions[index].expressionGroup = event.target.value;
    setAttributes([]);
  };

  const addExcepressionChild = (index) => {
    let newList = [
      ...props.values.inputconditions[index].expressions,
      {
        condition: "",
        display: "",
        elmtype: "",
        operator: "",
        operatordisplay: "",
        resultdisplay: "",
        result: "",
        expressions: [],
      },
    ];
    props.values.inputconditions[index].expressions = newList;
    //}
    setInputs(props.values.inputconditions);
    setAttributes([]);
  };

  const removeExpression = (cindex, index) => {
    let newList = props.values.inputconditions[cindex].expressions.filter(
      (_, i) => index !== i
    );
    props.values.inputconditions[cindex].expressions = newList;
    setInputs(props.values.inputconditions);
    setAttributes([]);
  };

  const deleteItem = (index) => () => {
    props.values.inputconditions = props.values.inputconditions.filter(
      (_, i) => index !== i
    );
    setInputs(props.values.inputconditions);
  };

  const attributesTypes = Object.keys(dataTypes);
  const objectFacts = props.values.facts.filter(
    (e) => e.type === "Object" && e.name !== ""
  );
  if (objectFacts.length > 0) {
    objectFacts.forEach((e) => attributesTypes.push(e.name));
  }

  const objVerbals = (elmtype) => {
    let oVerbals = [];
    let obj = helper.findObject(props.values.facts, elmtype);
    helper.guidedVerbalsProps(obj, oVerbals);
    return oVerbals;
  };

  return (
    <>
      <div style={{ position: "relative", padding: "12px" }} className="border">
        <Row className="border-bottom border-dark">
          <Col sm={10}>
            <span>{props.title}</span>
          </Col>
          <Col sm={2}>
            {(props.values.type === "decision" ||
              props.values.inputconditions.length === 0) && (
              <Button onClick={addItem} variant="link" disabled={props.remote}>
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            )}
          </Col>
        </Row>
        <Row className="mt-2">
          <Accordion defaultActiveKey="0">
            {props.values.inputconditions &&
              props.values.inputconditions.map((ic, i) => {
                let factsOptions = [];
                helper.guidedVerbals(
                  props.values.inputconditions[i].inputs,
                  props.values.inputconditions,
                  factsOptions,
                  i,
                  props.businessConditions,
                );
                return (
                  <Accordion.Item eventKey={i}>
                    <Accordion.Header>
                      {ic.title || "...condition title..."}
                    </Accordion.Header>
                    <Accordion.Body>
                      <Row>
                        <Col sm={1}>
                          <Form.Label>Title</Form.Label>
                        </Col>
                        <Col sm={3}>
                          <Form.Control
                            size="sm"
                            type="input"
                            label={`Title`}
                            value={props.values.inputconditions[i].title}
                            onChange={(e) => onChangeTitle(e, i)}
                            disabled={props.remote || props.values.type === "condition" }
                          />
                        </Col>
                        <Col sm={1}>
                          <Form.Label>Inputs</Form.Label>
                        </Col>
                        <Col sm={6}>
                          <Row>
                            {props.values.inputs.map((inp, j) => {
                              return (
                                <Col>
                                  <Form.Check
                                  disabled={props.remote}
                                    type="checkbox"
                                    label={inp.name}
                                    value={inp.name}
                                    onChange={(e) =>
                                      onInputSelectChange(e, i, j)
                                    }
                                    checked={isInputSelected(inp.name, i)}
                                  />
                                </Col>
                              );
                            })}
                          </Row>
                        </Col>
                        <Col sm={1}>
                          {props.values.type === "decision" && (
                            <ButtonGroup>
                              <Button variant="link" onClick={deleteItem(i)} disabled={props.remote}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </Button>
                            </ButtonGroup>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col sm="3">
                          <Form.Select
                          disabled={props.remote}
                            style={{ fontSize: "12px" }}
                            value={
                              props.values.inputconditions[i].expressionGroup
                            }
                            onChange={(event) =>
                              changeExpressionGroup(i, event)
                            }
                          >
                            <option value="&& ">All Of the following</option>
                            <option value="|| ">Any of the following</option>
                          </Form.Select>
                        </Col>
                        <Col sm="2">
                          <Form.Label>Expressions</Form.Label>
                        </Col>

                        <Col sm="1">
                          <Button
                            onClick={() => addExcepressionChild(i)}
                            variant="link"
                            disabled={props.remote}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        </Col>
                      </Row>
                      {props.values.inputconditions[i].expressions.map(
                        (ex, j) => {
                          return (
                            <Expression
                            remote={props.remote}
                              expression={ex}
                              options={factsOptions}
                              index={j}
                              facts={props.values.facts}
                              removeExpression={() => removeExpression(i, j)}
                            />
                          );
                        }
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
          </Accordion>
        </Row>
      </div>
    </>
  );
};

export default InputsConditions;
