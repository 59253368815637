import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
//import RuleViewer from "./RuleViewer";
import PresentationRuleViewer from "./PresentationRuleViewer";

const RideSideView = (props) => {
  return (
    <>
      
      <Tabs
        defaultActiveKey="presentation"
        id="justify-tab-example"
        className="mb-3"
        justify
      >
        <Tab eventKey="presentation" title="Presentation Rule">
          <PresentationRuleViewer
            setRule={props.setRule}
            setRules={props.setRules}
          />
        </Tab>
        {/*<Tab eventKey="businesRule" title="Business Rule">
          <RuleViewer
            setRule={props.setRule}
            setRules={props.setRules}
          />
  </Tab>*/}
        
      </Tabs>
    </>
  );
};

export default RideSideView;
