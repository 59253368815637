import { faPlusSquare, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import MultiSelectDropdown from "./MultiSelectDropdown";
import AddFilterModal from "./AddFilterModal";

const CheckBoxOption = (props) => {
  const [values, setValues] = useState(props.data.answer.values);
  const [filters, setFilters] = useState(props.data.filters);
  const onClickAddValue = () => {
    let temp = [...values];
    temp.push({ code: "", decode: "", filterList: [] });
    props.data.answer.values = temp;
    setValues(temp);
  };
  const onClickRemoveValue = (index) => {
    let temp = [...values];
    temp.splice(index, 1);
    setValues(temp);
  }
  const onChangeCode = (event, index) => {
    let temp = [...values];
    temp[index].code = event.target.value;
    props.data.answer.values = temp;
    setValues(temp);
  };
  const onChangeDeCode = (event, index) => {
    let temp = [...values];
    temp[index].decode = event.target.value;
    props.data.answer.values = temp;
    setValues(temp);
  };
  const [showAddFilter, setShowAddFilter] = useState(false);
  const onClickAddFilter = () => {
    setShowAddFilter(true);
  }

  const onAddFilter = (name, dependency) => {
    let temp = [...filters];
    temp.push({ref: name, dependencyKey: {ref: dependency}});
    setFilters(temp);
    //props.data.filters.push({ref: name, dependency: {ref: dependency}});
    props.data.filters = temp;
    setShowAddFilter(false);
  }
  const onSelectedOptions = (opt, index) => {
    //alert(index);
    //console.log(opt);
    //setSelectedOptions(opt);
    let temp = [...values];
    temp[index].filterList = opt;
    setValues(temp);
  }
  return (
    <>
      <Row
        className="mt-2"
        style={{
          borderBottom: "solid 1px",
          //overflowY: "auto",
          //maxHeight: "200px",
        }}
      >
        <Col sm="1"></Col>
        <Col sm="11">
          <Row>
            <Col sm="3">
              <strong>Options:</strong>{" "}
              <Button variant="outline-primary" size="sm" onClick={() => onClickAddFilter()}>
                Add Filter
              </Button>{" "}
              <Button variant="outline-primary">
                <FontAwesomeIcon
                  style={{ float: "right" }}
                  icon={faPlusSquare}
                  onClick={() => onClickAddValue()}
                />
              </Button>
            </Col>
            <Col sm="9"></Col>
          </Row>
          {values.map((v, k) => {
            return (
              <>
                <Row className="mt-2" style={{ borderTop: "solid 1px" }}>
                  <Col sm="1" className="mt-2">
                    <FontAwesomeIcon icon={faX} onClick={() => onClickRemoveValue(k)}/>
                  </Col>
                  <Col sm="1" className="mt-2">
                    Code
                  </Col>
                  <Col sm="2" className="mt-2">
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="code.."
                      value={v.code}
                      onChange={(event) => onChangeCode(event, k)}
                    />
                  </Col>
                  <Col sm="1" className="mt-2">
                    Decode
                  </Col>
                  <Col sm="3" className="mt-2">
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="decode.."
                      value={v.decode}
                      onChange={(event) => onChangeDeCode(event, k)}
                    />
                  </Col>
                  <Col sm="4" className="mt-2">
                    <MultiSelectDropdown data={props.data} filters={filters} selectedOptions={v.filterList}
                     onSelectedOptions={(opt) => onSelectedOptions(opt, k)}/>
                  </Col>
                </Row>
              </>
            );
          })}
          <Row className="mt-2"></Row>
          {showAddFilter && <AddFilterModal data={props.data} show={showAddFilter} 
          handleClose={setShowAddFilter}
          onAddFilter={onAddFilter} rules={props.rules} />}
        </Col>
      </Row>
    </>
  );
};
export default CheckBoxOption;
