import textVerbals from '../data/verbalization.json'
// eslint-disable-next-line no-extend-native
String.prototype.format = function () {
    // store arguments in an array
    var args = arguments;
    // use replace to iterate over the string
    // select the match and check if the related argument is present
    // if yes, replace the match with the argument
    return this.replace(/{([0-9]+)}/g, function (match, index) {
        // check if the argument is present
        return typeof args[index] == 'undefined' ? match : args[index];
    });
};
function camelToSentenceCase(str) {
    const result = str.replace(/([A-Z])/g, ' $1');
    const final = result.toLowerCase();
    return final;
}

function camelCase(str) {
    return str && str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

function isObject(elmtype) {
    if (!['String', 'Number', 'Double', 'Boolean', 'xpath'].includes(elmtype)) {
        return true;
    }
    return false;
}

function getDefinedObject(facts, elem) {
    let exist = facts.filter(e => e.name === elem.type);
    console.log(exist);
    if (exist && exist.length > 0) {
        return exist[0];
    } else {
        return null;
    }
}

function findObject(facts, elmtype) {
    return isObject(elmtype) ? facts.filter(e => e.name === elmtype)[0] : null;
}

function buildParams(inputs) {
    return inputs && inputs.map(e => "{0} : {1}".format(e.name, e.type)).join(', ');
}

const ruleName = (obj) => {
    return "Rule {0} {1}({2})"
        .format(obj.outcome ? (obj.outcome.name !== '' ? obj.outcome.type : 'void') : 'boolean',
            camelCase(obj.name), buildParams(obj.inputs));
}

const guidedVerbalsProps = (obj, verbalOptions) => {
    obj.attributes.forEach(e => {
        let isItObject = isObject(e.type);
        if (isItObject && e.array) {
            let vText = textVerbals['ArrayObject'];
            vText.forEach(t => {
                verbalOptions.push({
                    name: t.exp.format(e.name), verbal: t.text.format(camelToSentenceCase(e.name)),
                    type: e.type, array: e.array,
                    operators: t.operators,
                    orAndType: t.orAndType,
                    showResult: t.result

                });
            })

        } else {
            let vText = textVerbals[e.type];
            vText.forEach(t => {
                verbalOptions.push({
                    name: t.exp.format(e.name), verbal: t.text.format(camelToSentenceCase(e.name)),
                    type: e.type, array: e.array,
                    operators: t.operators,
                    orAndType: t.orAndType,
                    showResult: t.result
                });
            });
        }

    })
}

const guidedVerbals = (inputs, inputConditions, verbalOptions, index = 0, businessConditions = []) => {
    if (inputs.length === 0) {
        verbalOptions.push({
            name: "returns true", verbal: "returns true",
            type: 'Always', array: '',
            operators: [],
            orAndType: false,
            showResult: false
        });
        verbalOptions.push({
            name: "returns false", verbal: "returns false",
            type: 'Always', array: '',
            operators: [],
            orAndType: false,
            showResult: false
        });
    }
    if (inputConditions && inputConditions.length > 1) {
        inputConditions.map((inc, i) => {
            if (i !== index) {
                verbalOptions.push({
                    name: inputConditionMethod(inc), verbal: inc.title,
                    type: 'condRef', array: false, operators: [],
                    orAndType: false,
                    showResult: false
                });
            }
            return "";
        })
    }

    //alert(JSON.stringify(businessConditions));
    businessConditions.forEach(r => {
        if (inputConditions.length > 0 && inputConditions[0].title !== r.name) {
            verbalOptions.push({
                name: inputConditionMethod(r.inputconditions[0]), verbal: r.inputconditions[0].title,
                type: 'condRef', array: false, operators: [],
                orAndType: false,
                showResult: false
            });
        }
    })
    /*let businessRules = localStorage.getItem('myProjects');
    if(businessRules) {
        businessRules = JSON.parse(businessRules);
        businessRules.forEach(p => {
            p.features.forEach(f => {
                f.rules.filter(r => r.type === 'condition').forEach(r => {
                    if (inputConditions.length > 0 && inputConditions[0].title !== r.name) {
                        verbalOptions.push({
                            name: inputConditionMethod(r.inputconditions[0]), verbal: r.inputconditions[0].title,
                            type: 'condRef', array: false, operators: [],
                            orAndType: false,
                            showResult: false
                        });
                    }
                })
            })
        })
    }*/

    inputs.forEach(e => {
        if (isObject(e.type)) {
            //let definedObj = getDefinedObject(facts, e);
            let vText = textVerbals['Object'];
            vText.forEach(t => {
                verbalOptions.push({
                    name: t.exp.format(e.name), verbal: t.text.format(camelToSentenceCase(e.name)),
                    type: e.type, array: e.array,
                    operators: t.operators,
                    orAndType: t.orAndType,
                    showResult: t.result
                });
            });
        } else {
            let vText = textVerbals[e.type];
            vText.forEach(t => {
                verbalOptions.push({
                    name: e.name, verbal: t.text.format(camelToSentenceCase(e.name)),
                    type: e.type, array: e.array,
                    operators: t.operators,
                    orAndType: t.orAndType,
                    showResult: t.result
                });
            });
        }
    });


}



const verbals = (inputs, facts, inputconditions) => {
    console.log(inputs);
    let verbalOptions = [];
    inputs.forEach(e => {
        let definedObj = getDefinedObject(facts, e);
        if (definedObj != null) {
            console.log(definedObj);
            verbalOptions.push({ name: e.name, verbal: camelToSentenceCase(e.name), type: definedObj.type, array: e.array });
            definedObj.attributes.forEach(att => {
                if (att.type === 'Boolean') {
                    verbalOptions.push({ name: e.name + '.' + att.name, verbal: camelToSentenceCase(e.name) + " is " + camelToSentenceCase(att.name), type: att.type, array: att.array });
                } else {
                    /*if (e.array) {
                        verbalOptions.push({ name: e.name + '.' + att.name, verbal: camelToSentenceCase(att.name) + " of " + camelToSentenceCase(e.name), type: att.type });
                    }*/
                    verbalOptions.push({ name: e.name + '.' + att.name, verbal: camelToSentenceCase(att.name) + " of " + camelToSentenceCase(e.name), type: att.type, array: att.array });
                }
            })
        } else {
            /*if (e.array) {
                verbalOptions.push({ name: e.name, verbal: 'one of ' + camelToSentenceCase(e.name), type: e.type });
            } */
            verbalOptions.push({ name: e.name, verbal: camelToSentenceCase(e.name), type: e.type, array: e.array });

        }
    });
    if (inputconditions) {
        inputconditions.map((inc, i) => {
            verbalOptions.push({ name: inputConditionMethod(inc), verbal: inc.title, type: 'Boolean', array: false });
            return "";
        })
    }
    console.log(verbalOptions);
    return verbalOptions;
}

const verbalOutcome = (inputs, output, facts) => {
    console.log(inputs);
    let verbalOptions = [];
    inputs.forEach(e => {
        let definedObj = getDefinedObject(facts, e);
        if (definedObj != null) {
            console.log(definedObj);
            verbalOptions.push({ name: e.name, verbal: 'Set ' + camelToSentenceCase(e.name) + ' as', type: definedObj.type });
            definedObj.attributes.forEach(att => {
                if (att.type === 'Boolean') {
                    verbalOptions.push({ name: e.name + '.' + att.name, verbal: 'Set ' + camelToSentenceCase(e.name) + " is " + camelToSentenceCase(att.name), type: att.type, input: 'yes' });
                    verbalOptions.push({ name: e.name + '.' + att.name, verbal: 'Set ' + camelToSentenceCase(e.name) + " is not " + camelToSentenceCase(att.name), type: att.type, input: 'yes' });
                } else {
                    verbalOptions.push({ name: e.name + '.' + att.name, verbal: 'Set ' + camelToSentenceCase(att.name) + " of " + camelToSentenceCase(e.name) + ' as ', type: att.type, input: 'yes' });
                }
            })
        } else {
            verbalOptions.push({ name: e.name, verbal: 'Set ' + camelToSentenceCase(e.name) + ' as', type: e.type, input: 'yes' });
        }
    });

    if (output) {
        let outputObj = getDefinedObject(facts, output);
        if (outputObj != null) {
            outputObj.attributes && outputObj.attributes.forEach(att => {
                if (att.type === 'Boolean') {
                    verbalOptions.push({ name: output.name + '.' + att.name, verbal: 'Set ' + camelToSentenceCase(output.name) + " is " + camelToSentenceCase(att.name), type: att.type });
                    verbalOptions.push({ name: output.name + '.' + att.name, verbal: 'Set ' + camelToSentenceCase(output.name) + " is not " + camelToSentenceCase(att.name), type: att.type });
                } else {
                    verbalOptions.push({ name: output.name + '.' + att.name, verbal: 'Set ' + camelToSentenceCase(att.name) + " of " + camelToSentenceCase(output.name) + ' as ', type: att.type });
                }
            })
        } else {
            verbalOptions.push({ name: output.name, verbal: 'Set ' + camelToSentenceCase(output.name) + ' as ', type: output.type });
        }
    }
    verbalOptions.push({ name: 'throw ', verbal: 'Raise error' });
    console.log(verbalOptions);
    return verbalOptions;
}

function fetchConditions(conditions) {
    return conditions.filter(c => c.result && c.result !== '').map(c => {
        let res = c.result;

        if (['in', 'not in'].includes(c.operator)) {
            if (c.elmtype === 'String') {
                res = '[' + c.result.split(',').map(e => "'" + e.trim() + "'").join(', ') + ']';
                return "{2}{0}.includes({1})".format(res, c.condition, 'not in' === c.operator ? '!' : '');
            }
            res = '[' + c.result + ']';
            return "{2}{0}.includes({1})".format(res, c.condition, 'not in' === c.operator ? '!' : '');
        } else if (c.operator === 'between') {
            if (res.includes('-')) {
                let range = res.split('-');
                let conds = [];
                conds.push("{0} {1} {2}".format(c.condition, '>=', range[0].trim()));
                conds.push("{0} {1} {2}".format(c.condition, '<=', range[1].trim()));
                return conds.join(' && ');
            } else {
                let range = res.replace('[', '').replace(']', '').split(',');
                let conds = [];
                conds.push("{0} {1}{3} {2}".format(c.condition, '>', range[0].trim(), res.startsWith('[') ? '=' : ''));
                conds.push("{0} {1}{3} {2}".format(c.condition, '<', range[1].trim(), res.endsWith(']') ? '=' : ''));
                return conds.join(' && ');
            }
        } else if ('exists' === c.operator) {
            return "{0} {1} {2}".format(c.condition, '!==', 'null');
        } else if (['true', 'false'].includes(c.operator) && c.elmtype === 'Boolean') {
            return "{0}{1}".format(c.operator === 'false' ? '!' : '', c.condition);
        } else if ('does not exist' === c.operator) {
            return "{0} {1} {2}".format(c.condition, '===', 'null');
        } else {
            if (c.elmtype === 'String') {
                return "{0} {1} '{2}'".format(c.condition, c.operator, res);
            } else if (c.elmtype === 'Boolean') {
                return "{0}{1}".format(['true', 'yes'].includes(res.toLowerCase()) ? '' : '!', c.condition);
            }
            return "{0} {1} {2}".format(c.condition, c.operator, res);
        }


    }).join(' && ');
}

const generate = (data) => {
    const name = camelCase(data.name);//.replaceAll(' ', '');
    const inputs = data.inputs.map(e => e.name).join(', ');
    //let code = "class {0} { \n".format(name);
    let code = data.inputconditions.map(ic => {
        return inputConditionFunction(ic, data.facts);
    }).join("\n\n");

    //code = code + "\texport default function {0}({1}) {\n".format(name, inputs);
    //code = code + "\t{0}({1}) {\n".format(name, inputs);
    code = code + "\nconst {0} = function ({1}) {\n".format(name, inputs);
    let returnnull = true;
    data.decisions && data.decisions.forEach(d => {
        let conditions = fetchConditions(d.conditions);
        if (conditions) {
            code = code + "\t\tif({0}) {\n".format(conditions);
            if (isObject(data.outcome.type)) {
                code = code + "\t\t\tvar {0} = {1};\n".format(data.outcome.name, '{}');
            }
            d.outcomes.forEach(o => {
                if (o.value.trim().startsWith("=")) {
                    code = code + "\t\t\t{0} = {1};\n".format(o.expression,
                        o.value.replace("=", ""));
                } else if (o.expression.startsWith('throw')) {
                    code = code + "\t\t\t{0} {1};\n".format(o.expression, o.value.split('+').map(e => {
                        if (e.trim().startsWith("=")) {
                            return e.trim().replace('=', '');
                        }
                        return "'" + e.trim() + "'";
                    }).join(" + "));
                } else if (o.input && o.input.startsWith('yes')) {
                    code = code + "\t\t\t{0} = {1};\n".format(o.expression,
                        o.elmtype === 'String' ? "'{0}'".format(o.value) : o.value);
                } else if (o.expression && o.expression.includes('.')) {
                    code = code + "\t\t\t{0} = {1};\n".format(o.expression,
                        o.elmtype === 'String' ? "'{0}'".format(o.value) : o.value);
                } else {
                    code = code + "\t\t\tvar {0} = {1};\n".format(o.expression,
                        o.elmtype === 'String' ? "'{0}'".format(o.value) : o.value);
                }
            })
            if (data.outcome.name !== '') {
                code = code + "\t\t\treturn {0};\n".format(data.outcome.name);
            }

            code = code + "\t\t}\n"
        } else {
            if (data.outcome.name !== '' && d.outcomes.length === 1 && d.outcomes[0].value !== '') {
                returnnull = false;
                code = code + "\t\treturn {0};\n".format(d.outcomes[0].elmtype === 'String' ? "'{0}'".format(d.outcomes[0].value) : d.outcomes[0].value);
            }
        }

    });
    if (data.outcome && data.outcome.name !== '' && returnnull) {
        code = code + "\t\treturn null;\n";
    }
    code = code + "\t}\n";
    return code;
}

const inputConditionMethod = (condition) => {
    if (condition) {
        let title = condition.title ? condition.title.replace('?', '') : '';
        let inputs = condition.inputs.map(e => e.name).join(', ')
        return "{0}({1})".format(camelCase(title), inputs)
    }
    return '';
}

const inputConditionFunction = (condition, facts) => {
    let funcName = inputConditionMethod(condition);

    let code = 'function {0}{\n'.format(funcName);
    code = code + "\treturn {0};\n".format(condition.expressions.map(ex => {
        return expression2Code(ex, facts)
    }).join(condition.expressionGroup ? condition.expressionGroup : '&& '));
    code = code + "}\n";
    return code;
}

const expression2Code = (exp, facts, parent) => {
    let code = '';
    let obj = findObject(facts, exp.elmtype);
    if (obj) {
        let isArray = ['find', 'size', 'every'].find(e => exp.condition.includes(e))
        if (isArray) {
            let conds = exp.condition.split('.');
            let condPrefix = conds[0];
            let condLoop = conds[1].includes('find') ? '{0}.find(e => {1})' : (conds[1].includes('every') ? '{0}.every(e => {1})' : '');
            let newParent = parent ? '{0}.{1}'.format(parent, condPrefix) : condPrefix;
            code = code + '{0}{1}{2}'.format(newParent,
                exp.expressions.length > 0 ? ' && ' : '',
                condLoop.format(newParent, exp.expressions.map((ex, i) => {
                    return expression2Code(ex, facts, 'e')
                }).join(exp.operator))
            );
        } else {
            let newParent = parent ? '{0}.{1}'.format(parent, exp.condition) : exp.condition;
            code = code + '{0}{1}{2}'.format(newParent,
                exp.expressions.length > 0 ? ' && ' : '',
                exp.expressions.map((ex, i) => {
                    return expression2Code(ex, facts, newParent)
                }).join(exp.operator)
            );
        }
    } else {
        let newParent = parent ? '{0}.{1}'.format(parent, exp.condition) : exp.condition;
        code = code + '{0} {1} {2}'.format(newParent, exp.operator,
            exp.result.trim().startsWith('=') ? exp.result.trim().replace('=', '')
                : (exp.elmtype === 'String' ? "'{0}'".format(exp.result.trim()) : exp.result.trim())
        )
    }
    return code;
}


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    findObject, verbals, verbalOutcome, ruleName, generate,
    camelCase, camelToSentenceCase, isObject, guidedVerbals,
    guidedVerbalsProps, inputConditionMethod
};