import axios from "axios";
import { useState } from "react";
import { Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Settings = (props) => {
  const [user, setUser] = useState("");
  const [token, setToken] = useState("");

  const onChangeUser = (event) => {
    setUser(event.target.value);
  };

  const onChangeToken = (event) => {
    setToken(event.target.value);
  };

  //const handleClose = () => setShow(false);
  //const handleShow = () => setShow(true);
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  const saveSettings = () => {
    localStorage.setItem('settings', JSON.stringify({'user': user, 'token': token}));
    props.handleClose();
    /*axios
      .post(`${BASE_URL}/api/rules/settings`, { owner: user, token: token })
      .then((response) => {
        console.log(response.data);
        props.handleClose();
      })
      .catch((ex) => {
        console.log(ex);
      });*/
  };

  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Settings...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-1 font12">
            <div class="custom-input-wrapper">
              <label className="custom-label" for="user">
                Git User
              </label>
              <input
                className="custom-input"
                type="text"
                placeholder="Git User"
                onChange={(event) => onChangeUser(event)}
              />
            </div>
          </Row>
          <Row className="mt-3 font12">
            <div class="custom-input-wrapper">
              <label className="custom-label" for="user">
                Git Token
              </label>
              <input
                className="custom-input"
                type="text"
                placeholder="Git token"
                onChange={(event) => onChangeToken(event)}
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => saveSettings()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Settings;
