/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Panel,
} from "reactflow";
import "reactflow/dist/style.css";
import CustomNode from "./CustomNode";
import PresentationConfig from "./PresentationConfig";
import Page from "./Page";
import Question from "./Question";
import { Alert, Button, Col, Row } from "react-bootstrap";
import CodeView from "./CodeView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faCodeCommit,
  faCodeFork,
  faDownload,
  faEye,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import RideSideView from "./RightSideView";
import SaveAlert from "./SaveAlert";
import OnSaveEditor from "./PresentationRuleImport";
import ViewUI from "./ViewUI";
import CommitSource from "./prms/CommitSource";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
//const initialNodes = [];
let id = 0;
const getId = () => `dndnode_${id++}`;

const nodeTypes = {
  custom: CustomNode,
  PresentationConfig: PresentationConfig,
  Page: Page,
  Question: Question,
};

const EditorView = (props) => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const [project, setProject] = useState("");
  const [feature, setFeature] = useState("");
  const [rule, setRule] = useState("");
  const [viewCode, setViewCode] = useState(false);
  const handleCloseCode = () => setViewCode(false);
  const [rules, setRules] = useState([]);
  const [isRemoteRule, setIsRemoteRule] = useState(false);
  const [ruleId, setRuleId] = useState("");
  //const [ruleCode, setRuleCode] = useState('');

  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const handleRulesChange = (rulesObj) => {
    setRules(rulesObj);
    setNodes(nodes);
    setEdges(edges);
  };

  function buildFilterInfo(questionNode, code, questionobj, configKey) {
    let filterList = [];

    questionNode.data.answer.values.forEach((v) => {
      v.filterList.forEach((fl) => {
        let ifFillterAdded = filterList.find((f) => f.ref === fl.label) || null;
        if (!ifFillterAdded) {
          let findFilterObject =
            questionNode.data.filters.find((f) => f.ref === fl.label) || null;
          if (findFilterObject) filterList.push(findFilterObject);
        }
        let filterItem =
          code[configKey].filterInfo.filter.find((f) => f.ref === fl.label) ||
          null;
        if (!filterItem) {
          filterItem = { ref: fl.label, "filter-item": [v.code] };
          code[configKey].filterInfo.filter.push(filterItem);
        } else {
          if (!filterItem["filter-item"].includes(v.code))
            filterItem["filter-item"].push(v.code);
        }
      });
    });
    if (filterList.length > 0) questionobj["filterList"] = filterList;
  }
  const exportNode = () => {
    var configNodes =
      nodes.find((obj) => obj.type === "PresentationConfig") || null;
    var code = {};
    if (configNodes) {
      let configKey = configNodes.data.name ? configNodes.data.name : "config";
      code = {};
      code[configKey] = {};
      var configEdges = edges.filter((e) => e.source === configNodes.id);
      configEdges.forEach((ce) => {
        var pageNode = nodes.find((obj) => obj.id === ce.target) || null;
        if (pageNode) {
          let pageKey = pageNode.data.name ? pageNode.data.name : "page";
          code[configKey][pageKey] = [];
          code[configKey]["dependencyInfo"] = { condition: [] };
          code[configKey]["filterInfo"] = { filter: [] };
          var questionEdges = edges.filter((e) => e.source === pageNode.id);
          var pageObj = { question: [] };
          questionEdges.forEach((qe) => {
            var questionNode =
              nodes.find((obj) => obj.id === qe.target) || null;
            console.log(questionNode);
            var questionobj = {
              dataBinding: questionNode.data.dataBinding,
              label: questionNode.data.label,
              rateBearing: questionNode.data.rateBearing.value,
            };
            if (questionNode.data.tooltip)
              questionobj.tooltip = questionNode.data.tooltip;
            if (questionNode.data.labelSubscript)
              questionobj.labelSubscript = questionNode.data.labelSubscript;
            if (questionNode.data.default)
              questionobj.default = { value: questionNode.data.default };
            switch (questionNode.data.answer.type) {
              case "radio":
                questionobj["answerRadio"] = {
                  option: questionNode.data.answer.values.map((v) => {
                    return { value: { code: v.code, decode: v.decode } };
                  }),
                };
                buildFilterInfo(questionNode, code, questionobj, configKey);
                break;
              case "textbox":
                questionobj["answerTextBox"] = {};
                if (questionNode.data.answer.mask)
                  questionobj["answerTextBox"]["mask"] =
                    questionNode.data.answer.mask;
                if (questionNode.data.answer.dataType)
                  questionobj["answerTextBox"]["dataType"] =
                    questionNode.data.answer.dataType;
                if (questionNode.data.answer.length)
                  questionobj["answerTextBox"]["length"] =
                    questionNode.data.answer.length;
                if (questionNode.data.answer.format)
                  questionobj["answerTextBox"]["format"] = {
                    value: questionNode.data.answer.format,
                  };
                break;
              case "dropdown":
                questionobj["answerDropDown"] = {
                  option: questionNode.data.answer.values.map((v) => {
                    return { value: { code: v.code, decode: v.decode } };
                  }),
                };
                buildFilterInfo(questionNode, code, questionobj, configKey);
                break;
              case "checkbox":
                questionobj["answerCheckBox"] = {
                  option: questionNode.data.answer.values.map((v) => {
                    return { value: { code: v.code, decode: v.decode } };
                  }),
                };
                buildFilterInfo(questionNode, code, questionobj, configKey);
                break;
              default:
                break;
            }
            if (questionNode.data.required.value) {
              if (questionNode.data.required.dependency) {
                questionobj["required"] = {
                  dependencyKey: {
                    ref: questionNode.data.required.dependency.ref,
                  },
                };

                let depKey = questionNode.data.required.dependency.ref;
                let conditions = fetchAllConditions(depKey, questionNode.data.rules);
                conditions &&
                  conditions.forEach((cond) => {
                    let isDepKeyAdded =
                      code[configKey].dependencyInfo.condition.find(
                        (c) => c.ref === cond.ref
                      ) || null;
                    if (!isDepKeyAdded) {
                      code[configKey].dependencyInfo.condition.push(cond);
                    }
                  });
              }
            }
            if (!questionNode.data.visible.value) {
              if (questionNode.data.visible.dependency) {
                questionobj["visible"] = {
                  value: false,
                  dependencyKey: {
                    ref: questionNode.data.visible.dependency.ref,
                  },
                };
                let depKey = questionNode.data.visible.dependency.ref;
                let conditions = fetchAllConditions(depKey, questionNode.data.rules);
                conditions &&
                  conditions.forEach((cond) => {
                    let isDepKeyAdded =
                      code[configKey].dependencyInfo.condition.find(
                        (c) => c.ref === cond.ref
                      ) || null;
                    if (!isDepKeyAdded) {
                      code[configKey].dependencyInfo.condition.push(cond);
                    }
                  });
              }
            }
            if (questionNode.data.enabled.value) {
              if (questionNode.data.enabled.dependency) {
                questionobj["enabled"] = {
                  dependencyKey: {
                    ref: questionNode.data.enabled.dependency.ref,
                  },
                };
                let depKey = questionNode.data.enabled.dependency.ref;
                let conditions = fetchAllConditions(depKey, questionNode.data.rules);
                conditions &&
                  conditions.forEach((cond) => {
                    let isDepKeyAdded =
                      code[configKey].dependencyInfo.condition.find(
                        (c) => c.ref === cond.ref
                      ) || null;
                    if (!isDepKeyAdded) {
                      code[configKey].dependencyInfo.condition.push(cond);
                    }
                  });
              }
            }
            if (questionNode.data.setValue.value) {
              if (questionNode.data.setValue.dependency) {
                questionobj["setValue"] = {
                  value: questionNode.data.setValue.value,
                  dependencyKey: {
                    ref: questionNode.data.setValue.dependency.ref,
                  },
                };
                let depKey = questionNode.data.setValue.dependency.ref;
                let conditions = fetchAllConditions(depKey, questionNode.data.rules);
                conditions &&
                  conditions.forEach((cond) => {
                    let isDepKeyAdded =
                      code[configKey].dependencyInfo.condition.find(
                        (c) => c.ref === cond.ref
                      ) || null;
                    if (!isDepKeyAdded) {
                      code[configKey].dependencyInfo.condition.push(cond);
                    }
                  });
              }
            }

            pageObj.question.push(questionobj);
          });
          code[configKey][pageKey].push(pageObj);
        }
      });
      console.log(code);
    }
    //setRuleJson(code);
    //setRuleViewer(false);
    return code;
  };

  const fetchAllConditions = (depKey, rulesData) => {
    let ruleTemp = rules && rules.length > 0 ? rules : rulesData;
    let depObj = ruleTemp.find((r) => r.ref === depKey) || null;
    let conditions = [];

    if (depObj) {
      conditions.push(depObj);
      let expressions = depObj["expression"];
      if (expressions) {
        expressions.forEach((exp) => {
          if (exp["arguments"] && exp["arguments"]["expression"]) {
            let argsExpressions = exp["arguments"]["expression"];
            argsExpressions.forEach((argExp) => {
              let expObj =
              ruleTemp.find((r) => r.ref === argExp["condRef"]) || null;
              if (expObj) {
                conditions.push(expObj);
              }
            });
          }
        });
      }
    }
    return conditions;
  };

  const downloadCode = (r) => {
    exportNode();
    const jsonContent = JSON.stringify(exportNode(), null, 2);
    const blob = new Blob([jsonContent], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${r}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  const onConnect = useCallback((params) => {
    setEdges((eds) => addEdge(params, eds));
  }, []);

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const type = event.dataTransfer.getData("application/reactflow");
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      let newNode = {
        id: getId(),
        type,
        position,
        data: { label: `${type} node`, name: "", filters: [] },
      };
      if (type === "Question") {
        newNode = {
          id: getId(),
          type,
          position,
          data: {
            rules: rules,
            filters: [],
            dataBinding: "",
            label: "",
            labelSubscript: "",
            tooltip: "",
            answer: {
              type: "",
              values: [],
              mask: "",
              dataType: "",
              format: "",
              length: "",
            },
            visible: {
              value: true,
              dependency: "",
            },
            required: {
              value: false,
              dependency: "",
            },
            enabled: {
              value: false,
              dependency: "",
            },
            setValue: {
              value: "",
              dependency: "",
            },
            rateBearing: {
              value: false,
            },
            default: "",
            filterList: [],
          },
        };
      }
      //props.initialNodes.push(newNode);
      //alert(isRemoteRule);
      //if (!isRemoteRule){
      setNodes((nds) => nds.concat(newNode));
      //}
    },
    [reactFlowInstance]
  );

  const onDropDisabled = useCallback(
    (event) => {
      event.preventDefault();
    },
    [reactFlowInstance]
  );

  function fetchDependency(ruleObj, dep) {
    return ruleObj.find((r) => r.ref === dep) || { ref: dep };
  }

  function buildFilters(opt, qt, presentationConfig) {
    let val = {
      code: opt.value.code,
      decode: opt.value.decode,
      filterList: [],
    };
    if (qt.filterList) {
      val.filterList = qt.filterList
        .filter((f) => {
          let filterInfo =
            presentationConfig.filterInfo["filter"].find(
              (fl) => fl.ref === f.ref
            ) || null;
          if (filterInfo) {
            return filterInfo["filter-item"].includes(opt.value.code);
          }
          return false;
        })
        .map((f) => {
          return { value: f.ref, label: f.ref };
        });
    }
    return val;
  }
  const buildType = (qt, presentationConfig) => {
    let answer = {
      type: "",
      values: [],
      mask: "",
      dataType: "",
      format: "",
      length: "",
    };
    //console.log(presentationConfig);
    if (qt.answerDropDown) {
      answer.type = "dropdown";
      qt.answerDropDown.option &&
        qt.answerDropDown.option.forEach((o) => {
          let val = buildFilters(o, qt, presentationConfig);
          answer.values = [...answer.values, val];
        });
    } else if (qt.answerCheckBox) {
      answer.type = "checkbox";
      qt.answerCheckBox.option &&
        qt.answerCheckBox.option.forEach((o) => {
          let val = buildFilters(o, qt, presentationConfig);
          answer.values = [...answer.values, val];
        });
    } else if (qt.answerTextBox) {
      answer.type = "textbox";
      answer.mask = qt.answerTextBox.mask || "";
      answer.dataType = qt.answerTextBox.dataType || "";
      answer.length = qt.answerTextBox.length || "";
      answer.format = qt.answerTextBox.format
        ? qt.answerTextBox.format.value
        : "";
    } else if (qt.answerRadio) {
      answer.type = "radio";
      qt.answerRadio.option &&
        qt.answerRadio.option.forEach((o) => {
          let val = buildFilters(o, qt, presentationConfig);
          answer.values = [...answer.values, val];
        });
    }
    console.log(answer);
    return answer;
  };

  const buildNodes = (code, ruleObj, isRemote = false) => {
    const x = 20;
    const y = 40;
    let nodes = [];
    let edges = [];
    if (code && Object.keys(code).length > 0) {
      const configId = getId();
      const configKey = Object.keys(code)[0];
      nodes.push({
        id: configId,
        position: { x: x, y: y },
        type: "PresentationConfig",
        data: { name: configKey },
      });
      const pageKey =
        Object.keys(code[configKey]).find(
          (k) => !["dependencyInfo", "filterInfo"].includes(k)
        ) || "page";
      const pages = code[configKey][pageKey];

      pages &&
        pages.forEach((pg) => {
          const pageId = getId();
          nodes.push({
            id: pageId,
            position: { x: x + 100, y: y },
            type: "Page",
            data: { name: pageKey },
          });
          edges.push({
            id: `dndnode_${configId}_${pageId}`,
            source: configId,
            sourceHanle: configId,
            target: pageId,
            targetHandle: pageId,
          });

          pg.question &&
            pg.question.forEach((qt, i) => {
              const qId = getId();
              nodes.push({
                id: qId,
                position: { x: x + 200, y: 25 * (2 * i + 1) },
                type: "Question",
                data: {
                  remote: isRemote,
                  rules: ruleObj,
                  filters: qt.filterList ? qt.filterList : [],
                  dataBinding: qt.dataBinding || "",
                  label: qt.label || "",
                  labelSubscript: qt.labelSubscript || "",
                  tooltip: qt.tooltip || "",
                  answer: buildType(qt, code[configKey]),
                  visible: {
                    value: qt.visible && qt.visible.value,
                    dependency:
                      qt.visible &&
                      qt.visible.dependencyKey &&
                      qt.visible.dependencyKey.ref
                        ? fetchDependency(ruleObj, qt.visible.dependencyKey.ref)
                        : "",
                  },
                  required: {
                    value: qt.required && !qt.required.value,
                    dependency:
                      qt.required &&
                      qt.required.dependencyKey &&
                      qt.required.dependencyKey.ref
                        ? fetchDependency(
                            ruleObj,
                            qt.required.dependencyKey.ref
                          )
                        : "",
                  },
                  enabled: {
                    value: qt.enabled && !qt.enabled.value,
                    dependency:
                      qt.enabled &&
                      qt.enabled.dependencyKey &&
                      qt.enabled.dependencyKey.ref
                        ? fetchDependency(ruleObj, qt.enabled.dependencyKey.ref)
                        : "",
                  },
                  setValue: {
                    value:
                      qt.setValue && qt.setValue.value ? qt.setValue.value : "",
                    dependency:
                      qt.setValue &&
                      qt.setValue.dependencyKey &&
                      qt.setValue.dependencyKey.ref
                        ? fetchDependency(
                            ruleObj,
                            qt.setValue.dependencyKey.ref
                          )
                        : "",
                  },
                  rateBearing: {
                    value: qt.rateBearing ? qt.rateBearing : false,
                  },
                  default:
                    qt.default && qt.default.value ? qt.default.value : "",
                },
              });
              edges.push({
                id: `dndnode_${pageId}_${qId}`,
                source: pageId,
                sourceHanle: pageId,
                target: qId,
                targetHandle: qId,
              });
            });
        });
    }
    return [nodes, edges];
  };

  const onChangeCode = (changedCode) => {
    try {
      const [tempNodes, tempEdges] = buildNodes(
        JSON.parse(changedCode) || { config: {} },
        rules
      );
      console.log(tempNodes);
      setNodes(tempNodes);
      setEdges(tempEdges);
      setViewCode(false);
    } catch (ex) {
      alert("Something went wrong!!!!");
    }
  };

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [variant, setVariant] = useState("");
  const [showSaveEditor, setShowSaveEditor] = useState(false);

  const onSelectRule = (
    project,
    feature,
    ruleName,
    code,
    rid,
    isRemote,
    pId,
    fId
  ) => {
    setProject(project);
    setFeature(feature);
    setRule(ruleName);
    setIsRemoteRule(isRemote);
    setRuleId(rid);
    //setRuleCode(code);
    setShowSaveEditor(false);
    //setCode(code);
    console.log(code);
    const [tempNodes, tempEdges] = buildNodes(
      code || { config: {} },
      rules,
      isRemote
    );
    console.log(tempNodes);
    setNodes(tempNodes);
    setEdges(tempEdges);
  };

  const onSelectRuleFromSaveEditor = (
    project,
    feature,
    ruleName,
    code,
    rid,
    isRemote,
    pId,
    fId
  ) => {
    setProject(project);
    setFeature(feature);
    setRule(ruleName);
    setIsRemoteRule(isRemote);
    setRuleId(rid);
    setShowSaveEditor(false);
    if (code && Object.keys(code).length > 0) {
      const [tempNodes, tempEdges] = buildNodes(
        code || { presentationConfig: {} },
        rules
      );
      console.log(tempNodes);
      setNodes(tempNodes);
      setEdges(tempEdges);
    }
  };
  const onCheckOut = (prj, feature, rid) => {
    axios
      .put(`${BASE_URL}/api/prms/rules/${rid}/checkout`, exportNode())
      .then((response) => {
        console.log(response.data);
        setIsRemoteRule(false);
      })
      .catch((ex) => {
        console.log(ex);
      });
    /*let myProjects = localStorage.getItem("myPresentationProjects");
    if (myProjects) {
      myProjects = JSON.parse(myProjects);
      myProjects.forEach((p) => {
        if (p.name === prj) {
          p.features.forEach((f) => {
            if (f.name === feature) {
              f.rules.forEach((r) => {
                if (r.id === rid) {
                  if (r.origin === "remote") {
                    let settings = localStorage.getItem("settings");
                    if (settings) {
                      axios
                        .post(`${BASE_URL}/api/git/rule/content`, {
                          url: p.url,
                          token: settings.token,
                          path: r.path,
                        })
                        .then((response) => {
                          console.log(response.data);
                          r["code"] = response.data;
                          r.origin = "local";
                          setIsRemoteRule(false);
                          const [tempNodes, tempEdges] = buildNodes(
                            response.data || { config: {} },
                            rules,
                            false
                          );
                          console.log(tempNodes);
                          setNodes(tempNodes);
                          setEdges(tempEdges);
                          localStorage.setItem(
                            "myPresentationProjects",
                            JSON.stringify(myProjects)
                          );
                        })
                        .catch((ex) => {
                          console.log(ex);
                        });
                    }
                  }
                }
              });
            }
          });
        }
      });
    }*/
  };

  const onSaveEditor = (prj, f, r) => {
    console.log(prj, f, r);
    if (!(prj && f && r)) {
      setShowSaveEditor(true);
    } else {
      axios
        .post(`${BASE_URL}/api/prms/rules/${r}/code`, exportNode())
        .then((response) => {
          console.log(response.data);
          setShowAlert(true);
          setVariant("success");
          setAlertMessage("Rule saved successfully..");
        })
        .catch((ex) => {
          console.log(ex);
        });
    }
  };

  const [testCode, setTestCode] = useState(false);
  const handleCloseTestCode = () => setTestCode(false);

  const onClickCommit = (prj, feature, ruleName, rid) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CommitSource
            show={true}
            handleClose={() => onClose()}
            project={prj}
            feature={feature}
            ruleName={ruleName}
            rule={JSON.stringify(exportNode(), null, 2)}
            onCommit={() => onClose()}
          />
        );
      },
    });
  };

  return (
    <>
      {showSaveEditor && (
        <OnSaveEditor
          show={showSaveEditor}
          handleClose={() => setShowSaveEditor(false)}
          setRule={onSelectRuleFromSaveEditor}
          setRules={handleRulesChange}
        />
      )}
      {showAlert && (
        <SaveAlert
          variant={variant}
          message={alertMessage}
          closeAlert={() => setShowAlert(false)}
        />
      )}
      {viewCode && (
        <CodeView
          show={viewCode}
          handleClose={() => handleCloseCode()}
          code={JSON.stringify(exportNode(), null, 2)}
          onChangeCode={onChangeCode}
        />
      )}
      {testCode && (
        <ViewUI
          show={testCode}
          handleClose={() => handleCloseTestCode()}
          code={exportNode()}
        />
      )}
      <Row>
        {isRemoteRule && (
          <Alert variant="info" className="font12">
            This rule's origin is remote, please checkout to edit.
          </Alert>
        )}
        <Col sm="9" className="col-code">
          <ReactFlowProvider>
            <div className="drawflow col-left" ref={reactFlowWrapper}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onInit={setReactFlowInstance}
                onDrop={isRemoteRule ? onDropDisabled : onDrop}
                onDragOver={onDragOver}
                nodeTypes={nodeTypes}
                nodesDraggable={!isRemoteRule}
                nodesConnectable={!isRemoteRule}
                deleteKeyCode={!isRemoteRule ? "Delete" : ""}
                //elementsSelectable={!isRemoteRule}
              >
                <Controls position="top-left" />
                <Panel position="top-right" style={{ margin: "5px" }}>
                  <strong>
                    <span style={{ fontSize: "12px" }}>
                      {project} {feature && <span> | {feature}</span>}
                      {rule && <span> | {rule}</span>}
                    </span>{" "}
                  </strong>
                  <Button variant="outline-success" size="sm">
                    {isRemoteRule ? (
                      <FontAwesomeIcon
                        icon={faCodeFork}
                        onClick={() => onCheckOut(project, feature, ruleId)}
                        title="Checkout"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSave}
                        onClick={() => onSaveEditor(project, feature, ruleId)}
                        title="Save"
                      />
                    )}
                  </Button>{" "}
                  <Button variant="outline-primary" size="sm">
                    <FontAwesomeIcon
                      icon={faEye}
                      onClick={() => setViewCode(true)}
                    />
                  </Button>{" "}
                  <Button variant="outline-secondary" size="sm">
                    <FontAwesomeIcon
                      icon={faCheck}
                      onClick={() => setTestCode(true)}
                    />
                  </Button>{" "}
                  <Button variant="outline-secondary" size="sm">
                    <FontAwesomeIcon
                      icon={faDownload}
                      onClick={() => downloadCode(rule)}
                    />
                  </Button>{" "}
                  <Button variant="outline-danger" size="sm">
                    <FontAwesomeIcon
                      icon={faCodeCommit}
                      onClick={() => onClickCommit(project, feature, rule)}
                    />
                  </Button>{" "}
                </Panel>
              </ReactFlow>
            </div>
          </ReactFlowProvider>
        </Col>
        <Col sm="3">
          <Row className="CodeMirror mt-2">
            <Col sm="12" className="col-code">
              <RideSideView
                setRule={onSelectRule}
                setRules={handleRulesChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default EditorView;
