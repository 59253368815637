import {
  faCode,
  faCodeCommit,
  faGlobe,
  faHome,
  //faServer,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Row } from "react-bootstrap";
import helper from "../helper";
import React, { useState } from "react";
import CodeView from "./CodeView";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CommitSource from "./CommitSource";

const BRMSSummary = (props) => {
  const [showCode, setShowCode] = useState(false);
  const [code, setCode] = useState("");
  const onClickCodeView = (r) => {
    setCode(helper.generate(r));
    setShowCode(true);
  };

  const onClickCommit = (prj, feature, ruleName, r) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <CommitSource
            show={true}
            handleClose={() => onClose()}
            project={prj}
            feature={feature}
            ruleName={ruleName}
            rule={r}
            onCommit={() => onClose()}
          />
        );
      },
    });
    
  };

  return (
    <>
      {showCode && (
        <CodeView
          show={showCode}
          code={code}
          handleClose={() => setShowCode(false)}
        />
      )}

      <Row className="mt-2">
        <Col sm="12" className="brms-header">
          <span className="brms-underline">Summary View</span>
        </Col>
      </Row>
      <Row className="mt-4" style={{maxHeight:"calc(100vh - 150px)", overflow:"auto"}}>
        {props.projects &&
          props.projects.map((p, i) => {
            return (
              <>
                {p.features &&
                  p.features.map((f, j) => {
                    return (
                      <>
                        {f.rules &&
                          f.rules.map((r, k) => {
                            return (
                              <>
                                <Col sm="4">
                                  <Card className="shadow-lg">
                                    <Card.Header
                                      onClick={() =>
                                        props.onSelectRule(p._id, f._id, r._id,  p.url)
                                      }
                                    >
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            r.origin === "remote"
                                              ? faGlobe
                                              : faHome
                                          }
                                          title={
                                            r.origin === "remote"
                                              ? "remote"
                                              : "local"
                                          }
                                          color="blue"
                                        />{" "}
                                        {p.name} / {f.name}
                                      </span>
                                      <p style={{ marginBottom: "0" }}>
                                        {r.name}
                                      </p>
                                    </Card.Header>
                                    <Card.Body
                                      style={{
                                        minHeight: "150px",
                                        maxHeight: "150px",
                                        overflowY: "auto",
                                      }}
                                    >
                                      <Card.Text>
                                        <p className="brmsrulename">
                                          {helper.ruleName(r)}
                                        </p>
                                        <p className="brmsruledesc">
                                          Description: {r.description}
                                        </p>
                                        <p className="brmsruledesc">
                                          Conditions:{" "}
                                          <ul>
                                            {r.inputconditions &&
                                              r.inputconditions.map((ic, l) => {
                                                return (
                                                  <>
                                                    <li>{ic.title}</li>
                                                  </>
                                                );
                                              })}
                                          </ul>
                                        </p>
                                      </Card.Text>
                                    </Card.Body>
                                    <Card.Footer className="text-muted">
                                      <Button
                                        variant="outline-danger"
                                        onClick={() => onClickCodeView(r)}
                                      >
                                        <FontAwesomeIcon
                                          icon={faCode}
                                          title="Download Code"
                                        />
                                      </Button>{" "}
                                      <Button
                                        variant="outline-danger"
                                        onClick={() =>
                                          props.onDelete(p._id, f._id, r._id, r.name)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrashAlt}
                                          title="Delete Rule"
                                        />
                                      </Button>{" "}
                                      <Button
                                        variant="outline-danger"
                                        onClick={() =>
                                          onClickCommit(
                                            p.name,
                                            f.name,
                                            r.name,
                                            r
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faCodeCommit}
                                          title="Commit Rule"
                                        />
                                      </Button>{" "}
                                    </Card.Footer>
                                  </Card>
                                </Col>
                              </>
                            );
                          })}
                      </>
                    );
                  })}
              </>
            );
          })}
      </Row>
    </>
  );
};
export default BRMSSummary;
