import { Col, Modal, Row } from "react-bootstrap";
import PresentationRuleViewer from "./PresentationRuleViewer";
import { useState } from "react";
import ConfirmationModal from "./ConfirmationModal";

const OnSaveEditor = (props) => {
  const [show, setShow] = useState(false);
  const [project, setProject] = useState('');
  const [feature, setFeature] = useState('');
  const [rule, setRule] = useState('');
  const [code, setCode] = useState('');
  const [ruleId, setRuleId] = useState('');
  const [remote, setRemote] = useState(false);
  const handleClose = () => {
    setShow(false);
  }

  const handleAnswer = (answer) => {
    if (answer === 'Yes') {
      props.setRule(project, feature, rule, code, ruleId, remote, '', '');
    } else {
      setShow(false);
    }
  }

  const handleRuleSelection = (p, f, r, codeStr, rid,
    isRemote,
    pId,
    fId) => {
    console.log(p, f, r, codeStr);
    setProject(p);
    setFeature(f);
    setRule(f);
    setRuleId(rid);
    setRemote(isRemote);
    //alert(codeStr);
    if (codeStr && Object.keys(codeStr).length > 0) {
      setCode(codeStr);
      setShow(true);
    } else {
      props.setRule(p, f, r, codeStr, rid, isRemote, pId, fId);
    }
   }
  return (
    <>
    {show && <ConfirmationModal show={show} handleClose={handleClose} handleAnswer={handleAnswer} />}
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Or Create...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="CodeMirror mt-2">
            <Col sm="12">
              <PresentationRuleViewer
                setRule={handleRuleSelection}
                setRules={props.setRules}
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default OnSaveEditor;
