import React from "react";
import Select from "react-select";

const MultiSelectDropdown = (props) => {
  
  const customStyles = {
    menu: (base) => ({
      ...base,
      maxHeight: '120px', // Set the maximum height for the dropdown menu
      overflow:'auto'
    }),
  };

  return (
      <Select
        isMulti
        options={props.filters.map(f => {
          return {value: f.ref, label: f.ref};
        })}
        value={props.selectedOptions}
        onChange={props.onSelectedOptions}
        styles={customStyles}
        placeholder="Select Filters"
        isSearchable
      />
  );
};

export default MultiSelectDropdown;
