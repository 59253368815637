import { Button, Card, Col, Row } from "react-bootstrap";

const Main = (props) => {
  return (
    <>
      <Row>
        <Col sm="9">
          <img src="Designer.jpeg" alt="home" className="banner"  style={{width:"100%"}}/>
        </Col>
        <Col sm="3" style={{padding:"2%"}}>
          <Row style={{ textAlign: "center" }} className="mt-2">
          <Col sm="12" >
            <span
              style={{ fontWeight: "700", color: "#5a5a5a", fontSize: "26px" }}
            >
              Rules
            </span>
            <hr />
            </Col>
          </Row>
          <Row>
            <Col sm="12" >
              <Card style={{ width: "100%" }} className="shadow-lg">
                <Card.Body>
                  <Card.Title>Presentation Rules</Card.Title>
                  <hr/>
                  <Card.Text>
                  <span style={{ fontWeight: "300", color: "#5a5a5a", fontSize: "12px" }}>Build your presentation rules.</span>
                  </Card.Text> 
                  <Button variant="primary" onClick={() => props.setView('PRESENTATION')}>Get Started</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm="12">
              <Card style={{ width: "100%" }} className="shadow-lg">
                <Card.Body>
                  <Card.Title>Business Rules</Card.Title>
                  <hr/>
                  <Card.Text>
                    <span style={{ fontWeight: "300", color: "#5a5a5a", fontSize: "12px" }}>Build your business rules.</span>
                  </Card.Text>
                  <Button variant="primary" onClick={() => props.setView('BRMS')}>Get Started</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default Main;
