import { faCircleQuestion, faCog, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useState } from "react";
import { Card } from "react-bootstrap";
import AlertModal from "./AlertModal";
import LoadingSpinner from "./loading-spinner";

const SideView = (props) => {
    const [show, setShow] = useState(false);
    const [header, setHeader] = useState(false);
    const [message, setMessage] = useState(false);
    const [showLoading, setShowLoading] = useState(false);

    const handleClose = () => setShow(false);
    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };
    const BASE_URL = process.env.REACT_APP_BACKEND_URL;
    const deploy = () => {
        axios.post(`${BASE_URL}/api/push/rules`, { message: 'Commit All Changes' })
            .then((response) => {
                console.log(response.data);
                setShow(true);
                setHeader('Deploy Changess...');
                setMessage('Changes deployed successfully...');
            })
            .catch((ex) => {
                console.log(ex);
            });
    }
    const rebase = () => {
        setShowLoading(true);
        axios.get(`${BASE_URL}/api/rebase/rules`)
            .then((response) => {
                setShowLoading(false);
                console.log(response.data);
                setShow(true);
                setHeader('Rebase Master Branch...');
                setMessage('Rebased successfully...');
            })
            .catch((ex) => {
                setShowLoading(false);
                console.log(ex);
            });
    }
    return (
        <>
            {showLoading && <LoadingSpinner />}
            {show && <AlertModal show={show} handleClose={handleClose} header={header} message={message} />}
            <div className="drag-drawflow" style={{ paddingLeft: "0", paddingRight: "0" }} onDragStart={(event) => onDragStart(event, 'PresentationConfig')} draggable>

                <Card >
                    <Card.Body>
                        <Card.Title>
                            <FontAwesomeIcon icon={faCog} size="2x" style={{ paddingTop: "0.5rem", color: "#460073" }} />
                            <div><span style={{ fontSize: "12px", color: "#460073" }}><strong>Config</strong></span></div>
                        </Card.Title>
                    </Card.Body>
                </Card>
            </div>
            <div className="drag-drawflow" style={{ paddingLeft: "0", paddingRight: "0" }} onDragStart={(event) => onDragStart(event, 'Page')} draggable>
                <Card >
                    <Card.Body>
                        <Card.Title>
                            <FontAwesomeIcon icon={faGlobe} size="2x" style={{ paddingTop: "0.5rem", color: "#460073" }} />
                            <div><span style={{ fontSize: "12px", color: "#460073" }}><strong>Page</strong></span></div>
                        </Card.Title>
                    </Card.Body>
                </Card>

            </div>
            <div className="drag-drawflow" style={{ paddingLeft: "0", paddingRight: "0" }} onDragStart={(event) => onDragStart(event, 'Question')} draggable>

                <Card >
                    <Card.Body>
                        <Card.Title>
                            <FontAwesomeIcon icon={faCircleQuestion} size="2x" style={{ paddingTop: "0.5rem", color: "#460073" }} />
                            <div><span style={{ fontSize: "12px", color: "#460073" }}><strong>Question</strong></span></div>
                        </Card.Title>
                    </Card.Body>
                </Card>

            </div>
            <hr />
            <div className="drag-drawflow" style={{ paddingLeft: "0", paddingRight: "0" }}>

                <Card >
                    <Card.Body>
                        <Card.Title onClick={() => deploy()}>
                            <div><span style={{ fontSize: "12px", color: "#460073" }}><strong>Deploy</strong></span></div>
                        </Card.Title>
                    </Card.Body>
                </Card>

            </div>
            <div className="drag-drawflow" style={{ paddingLeft: "0", paddingRight: "0" }}>

                <Card >
                    <Card.Body>
                        <Card.Title onClick={() => rebase()}>
                            <div><span style={{ fontSize: "12px", color: "#460073" }}><strong>Rebase</strong></span></div>
                        </Card.Title>
                    </Card.Body>
                </Card>

            </div>
        </>
    )
}
export default SideView;