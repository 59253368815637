/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';

const AutoCompleteTextbox = (props) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (props.value) {
        setSelectedOption({value: props.value, label: props.value});
    }
  }, [props.value])
  
  const handleChange = (selected) => {
    props.handleChange(selected ? selected.value : '');
    setSelectedOption(selected);
  };

  return (
    <div>
      <CreatableSelect
        value={selectedOption}
        onChange={handleChange}
        options={props.options}
        isClearable
        placeholder="Type to search..."
        isMulti={false}
      />
    </div>
  );
};

export default AutoCompleteTextbox;
