import Modal from "react-bootstrap/Modal";

const AlertModal = (props) => {
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.message}</Modal.Body>
      </Modal>
    </>
  );
};

export default AlertModal;
