import React, { useState } from "react";
import { Row, Col, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import SpanToSelectElementMaker from "../SpanToSelectElementMaker";
//import {verbals, verbalOutcome} from "./helper";

import helper from "../helper";

const DecisionTable = (props) => {
    console.log(props)
    const [decisions, setDecisions] = useState(props.values.decisions);
    //const [operators, setOperators] = useState([]);
    const [showCondEle, setShowCondEle] = useState(-1);
    //const [attributes, setAttributes] = useState([]);
    const factsOptions = helper.verbals(props.values.inputs, props.values.facts, props.values.inputconditions);

    const onBlurCondition = (index) => {
        setShowCondEle(index);
    }
    const onChangeHeader = (event, index) => {
        let temp = [...decisions];
        factsOptions.filter(e => e.name === event.target.value).forEach(e => {
            props.values.headers[index].condition = event.target.value;
            props.values.headers[index].elmtype = e.type;
            props.values.headers[index].display = e.verbal;
        });
        temp.forEach(d => {
            d.conditions[index].condition = props.values.headers[index].condition;
            d.conditions[index].elmtype = props.values.headers[index].elmtype;
            d.conditions[index].display = props.values.headers[index].display;
        });
        props.values.decisions = [...temp];
        setShowCondEle(-1);
        setDecisions(temp);
    }

    const onConditionCellChange = (event, r_index, c_index) => {
        let temp = [...decisions];
        temp[r_index].conditions[c_index].resultdisplay = event.target.value;
        temp[r_index].conditions[c_index].result = event.target.value;
        temp[r_index].conditions[c_index].operatordisplay = ''
        temp[r_index].conditions[c_index].operator = '';

        if (temp[r_index].conditions[c_index].operator === '') {
            let value = event.target.value.trim();
            temp[r_index].conditions[c_index].operatordisplay = 'is equal to'
            temp[r_index].conditions[c_index].operator = '===';
            if (['Number', 'Double'].includes(props.values.headers[c_index].elmtype)) {
                if (value.startsWith(">")) {
                    if (value.replace(">", '').trim().startsWith('=')) {
                        temp[r_index].conditions[c_index].operatordisplay = 'is more than and equal to'
                        temp[r_index].conditions[c_index].operator = '>=';
                        temp[r_index].conditions[c_index].result = value.replace('>', '').replace('=', '').trim();
                    } else {
                        temp[r_index].conditions[c_index].operatordisplay = 'is more than'
                        temp[r_index].conditions[c_index].operator = '>';
                        temp[r_index].conditions[c_index].result = value.replace('>', '').trim();
                    }
                } else if (value.startsWith("<")) {
                    if (value.replace("<", '').trim().startsWith('=')) {
                        temp[r_index].conditions[c_index].operatordisplay = 'is less than and equal to'
                        temp[r_index].conditions[c_index].operator = '<=';
                        temp[r_index].conditions[c_index].result = value.replace('<', '').replace('=', '').trim();
                    } else {
                        temp[r_index].conditions[c_index].operatordisplay = 'is less than'
                        temp[r_index].conditions[c_index].operator = '<';
                        temp[r_index].conditions[c_index].result = value.replace('<', '').trim();
                    }
                } else if (value.startsWith("=")) {
                    if (value.replace("=", '').trim().startsWith('>')) {
                        temp[r_index].conditions[c_index].operatordisplay = 'is more than and equal to'
                        temp[r_index].conditions[c_index].operator = '>=';
                        temp[r_index].conditions[c_index].result = value.replace('>', '').replace('=', '').trim();
                    } else if (value.replace("=", '').trim().startsWith('<')) {
                        temp[r_index].conditions[c_index].operatordisplay = 'is less than and equal to'
                        temp[r_index].conditions[c_index].operator = '<=';
                        temp[r_index].conditions[c_index].result = value.replace('<', '').replace('=', '').trim();
                    } else {
                        temp[r_index].conditions[c_index].operatordisplay = 'is equal to'
                        temp[r_index].conditions[c_index].operator = '===';
                        temp[r_index].conditions[c_index].result = value.replace('=', '').trim();
                    }
                } else if (value.includes("+")) {
                    temp[r_index].conditions[c_index].operatordisplay = 'is more than'
                    temp[r_index].conditions[c_index].operator = '>';
                    temp[r_index].conditions[c_index].result = value.replace('+', '').trim();
                } else if (value.includes("-")) {
                    temp[r_index].conditions[c_index].operatordisplay = 'is in between'
                    temp[r_index].conditions[c_index].operator = 'between';
                } else if (value.includes(",")) {
                    temp[r_index].conditions[c_index].operatordisplay = 'is one of'
                    temp[r_index].conditions[c_index].operator = 'in';
                }

            } else if (['String'].includes(props.values.headers[c_index].elmtype)) {
                if (value.includes(",")) {
                    temp[r_index].conditions[c_index].operatordisplay = 'is one of'
                    temp[r_index].conditions[c_index].operator = 'in';
                }

            }
        }
        props.values.decisions = [...temp];
        setShowCondEle(-1);
        setDecisions(temp);
    }

    const onOutcomeCellChange = (event, r_index, c_index) => {
        let temp = [...decisions];
        temp[r_index].outcomes[c_index].value = event.target.value;
        temp[r_index].outcomes[c_index].valuedisplay = event.target.value;
        temp[r_index].outcomes[c_index].expression = props.values.outcome.name;
        props.values.decisions = [...temp];
        setShowCondEle(-1);
        setDecisions(temp);
        //setOperators({});
    }

    const addItem = () => {
        const newList = [...decisions];
        let newEntry = {
            outcomes: [],
            conditions: []
        }
        props.values.headers.forEach(h => {
            newEntry.conditions.push({
                type: 'and', condition: h.condition, display: h.display, elmtype: h.elmtype, operator: '',
                operatordisplay: '', resultdisplay: '', result: ''
            });
        })
        if(props.values.outcome.name !== ''){
            newEntry.outcomes.push({ value: '', valuedisplay: '', expression: '', display: '', elmtype: props.values.outcome.type });
        }
        
        newList.push(newEntry);
        props.values.decisions = [...newList];
        setDecisions(newList);
        setShowCondEle(-1);
    }

    const addColumn = index => () => {
        let temp = [...decisions];
        props.values.headers.push({ display: '', condition: '', elmtype: '' });
        temp.forEach(d => {
            d.conditions.push({
                type: 'and', condition: '', display: '', elmtype: '', operator: '',
                operatordisplay: '', resultdisplay: '', result: ''
            });
            d.outcomes.push({ value: '', valuedisplay: '', expression: '', display: '', elmtype: '' });
        })
        props.values.decisions = temp;
        setDecisions(temp);
        setShowCondEle(-1);
    }


    //const outputOptions = helper.verbalOutcome(props.values.inputs, props.values.outcome, props.values.facts);

    return (
        <>
            <div style={{ position: 'relative', padding: '12px' }} className="border">
                <Row className="border-bottom border-dark">
                    <Col sm={10}>{helper.ruleName(props.values)}</Col>
                    <Col sm={2}>
                        <Button onClick={addItem} variant="link" disabled={props.remote}>
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </Col>
                </Row>

                <Row className="justify-content-md-center">
                    <Table striped bordered hover style={{ width: '70%' }}>
                        <thead>
                            <tr>
                                {
                                    props.values.headers.map((header, i) => {
                                        return (
                                            <th>
                                                <SpanToSelectElementMaker
                                                remote={props.remote}
                                                    className={`conditionspan`}
                                                    name={`${props.id}[${0}].condition`}
                                                    options={factsOptions}
                                                    spanvalue={header.display ? header.display : 'Condition...'}
                                                    value={header.condition}
                                                    handleChange={(e) => { onChangeHeader(e, i) }}
                                                    handleDoubleClick={() => setShowCondEle(i)}
                                                    handleBlur={() => onBlurCondition(-1)}
                                                    showInputEle={i === showCondEle}
                                                />
                                                {i + 1 === props.values.headers.length && <Button variant="link" size="sm" disabled={props.remote} onClick={addColumn(i)}>
                                                    <FontAwesomeIcon icon={faPlus} />
                                                </Button>}
                                            </th>
                                        );
                                    })
                                }
                                {props.values.outcome.name && <th>{props.values.outcome.name}</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.values.decisions && props.values.decisions.map((_, i) => {
                                    return (
                                        <tr key={i} className="mb-2" style={{ position: 'relative', paddingTop: '12px' }}>
                                            {
                                                props.values.headers.map((_, j) => {
                                                    return (
                                                        <td>
                                                            <input
                                                            disabled={props.remote}
                                                                type="text"
                                                                value={props.values.decisions[i].conditions[j].resultdisplay}
                                                                onChange={(e) => onConditionCellChange(e, i, j)}
                                                            />

                                                        </td>
                                                    )
                                                })
                                            }
                                            {
                                                props.values.outcome.name &&
                                                <td>
                                                    <input
                                                    disabled={props.remote}
                                                        type="text"
                                                        value={props.values.decisions[i].outcomes[0].value}
                                                        onChange={(e) => onOutcomeCellChange(e, i, 0)}
                                                    />
                                                </td>

                                            }

                                        </tr>
                                    );

                                })
                            }

                        </tbody>
                    </Table>

                </Row>
            </div>
        </>
    );


}

export default DecisionTable;