import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import SpanToSelectElementMaker from "./SpanToSelectElementMaker";
import helper from "./helper";
import SpanToInputElementMaker from "./SpanToInputElementMaker";

const Expression = (props) => {
    const [showEle, setShowEle] = useState(-1);
    const [state, setState] = useState(props.expression/*{
        condition: '',
        display: '',
        elmtype: '',
        operator: '',
        operatordisplay: '',
        result: '',
        resultdisplay: '',
        expressions: []
    }*/);
    //console.log(state);

    const objVerbals = (elmtype) => {
        let oVerbals = [];
        let obj = helper.findObject(props.facts, elmtype);
        if (obj) {
            helper.guidedVerbalsProps(obj, oVerbals);
        }

        return oVerbals;
    }

    const filterConditionVerbal = () => {
        let cond = state.condition;
        let verbals = props.options.filter(e => e.name === cond);
        return verbals.length > 0 ? verbals[0] : null;
    }

    const onChangeCondition = (event, objVerbals) => {
        objVerbals.filter(e => e.name === event.target.value).forEach(e => {
            props.expression.condition = event.target.value;
            props.expression.elmtype = e.type;
            props.expression.display = e.verbal;
            props.expression.operator = '';
            props.expression.operatordisplay = '';
            props.expression.result = '';
            props.expression.resultdisplay = '';
            setState({
                condition: event.target.value,
                display: e.verbal,
                elmtype: e.type,
                operator: '',
                operatordisplay: '',
                result: '',
                resultdisplay: '',
                expressions: []
            })
            //setVerbals(objVerbals(e.type));
        });
        setShowEle(-1);
        //setAttributes([]);
    }

    const onChangeOperator = (event, index) => {
        props.expression.operator = event.target.value;
        props.expression.operatordisplay = event.target.options[event.target.selectedIndex].text;
        setState({
            ...state,
            operator: event.target.value,
            operatordisplay: event.target.options[event.target.selectedIndex].text
        })
        setShowEle(-1);
    }

    const onChangeResult = (event) => {
        props.expression.result = event.target.value;
        props.expression.resultdisplay = event.target.value;
        setState({
            ...state,
            result: event.target.value,
            resultdisplay: event.target.value
        })
        //setShowEle(-1);
    }

    const addExcepressionChild = () => {
        let newList = [...props.expression.expressions, {
            condition: '',
            display: '', elmtype: '', operator: '',
            operatordisplay: '', resultdisplay: '', result: '', expressions: []
        }]
        props.expression.expressions = newList;
        setState({
            ...state,
            expressions: newList
        })
    }

    const selConditionVerbal = filterConditionVerbal();

    /*const obj = helper.findObject(props.values.facts, props.obj.type);
    const factsOptions = {}
    helper.guidedVerbals(obj.attributes, props.values.facts, factsOptions);*/

    return (
        <>
            <Row>
                <Col sm={1} >
                                <Button variant="link" onClick={props.removeExpression}>
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                        </Col>
                <Col sm={4}>
                    <SpanToSelectElementMaker
                        className={`conditionspan`}
                        options={props.options}
                        spanvalue={state.display ? state.display : 'Select...'}
                        value={state.condition}
                        handleChange={(e) => { onChangeCondition(e, props.options) }}
                        handleDoubleClick={() => setShowEle('ec')}
                        handleBlur={() => setShowEle(-1)}
                        showInputEle={'ec' === showEle}
                    />
                </Col>
                {
                    state.condition && selConditionVerbal && selConditionVerbal.orAndType
                    &&
                    <>
                    <Row>
                        <Col sm={1}></Col>
                        <Col sm={10}>
                            <SpanToSelectElementMaker
                                className={`operatorspan`}
                                width={`50%`}
                                options={selConditionVerbal.operators}
                                value={state.operator}
                                spanvalue={state.operatordisplay ? state.operatordisplay : 'Select...'}
                                handleChange={(e) => { onChangeOperator(e, selConditionVerbal.operators) }}
                                handleDoubleClick={() => setShowEle('eo')}
                                handleBlur={() => setShowEle(-1)}
                                showInputEle={'eo' === showEle}
                            />

                            <Button onClick={() => addExcepressionChild()} variant="link">
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                            <hr className="grey" />
                            {
                                props.expression.expressions.map((ex, i) => {
                                    return (
                                        <Expression
                                            expression={ex}
                                            options={objVerbals(state.elmtype)}
                                            index={i}
                                            facts={props.facts}
                                        />
                                    )
                                })
                            }
                        </Col>




                    </Row>
                    </>
                }

                {
                    state.condition && selConditionVerbal && !selConditionVerbal.orAndType && !['Always', 'condRef'].includes(selConditionVerbal.type)
                    &&

                    <Col sm={3}>
                        <SpanToSelectElementMaker
                            className={`operatorspan`}
                            width={`50%`}
                            options={selConditionVerbal.operators}
                            value={state.operator}
                            spanvalue={state.operatordisplay ? state.operatordisplay : 'Select...'}
                            handleChange={(e) => { onChangeOperator(e, selConditionVerbal.operators) }}
                            handleDoubleClick={() => setShowEle('eo')}
                            handleBlur={() => setShowEle(-1)}
                            showInputEle={'eo' === showEle}
                        />
                    </Col>
                }
                {
                    state.operator && selConditionVerbal && selConditionVerbal.showResult
                    &&
                    <>
                        <Col sm={3}>
                            <SpanToInputElementMaker
                                value={state.result}
                                spanvalue={state.resultdisplay ? state.resultdisplay : 'value...'}
                                handleChange={(e) => { onChangeResult(e) }}
                                handleDoubleClick={() => setShowEle('er')}
                                handleBlur={() => setShowEle(-1)}
                                showInputEle={'er' === showEle}
                            />
                        </Col>
                    </>

                }


                <hr className="grey" />

            </Row>

        </>
    )

}
export default Expression;