import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const AddFilterModal = (props) => {
  const [name, setName] = useState("");
  const [dependency, setDependency] = useState("");
  const onChangeName = (event) => {
    setName(event.target.value);
  };
  const onChangeDependency = (event) => {
    setDependency(event.target.value);
  };

  /*const onAdd = () => {
    props.data.filters.push({ref: name, dependency: {ref: dependency}});
    props.handleClose();
  }*/
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Filter...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mt-2">
            <Col sm="3">Name</Col>
            <Col sm="9">
              <Form.Control
                size="sm"
                style={{ marginBottom: "3px" }}
                type="text"
                placeholder="Filter ref..."
                value={name}
                onChange={(event) => onChangeName(event)}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm="3">Condition</Col>
            <Col sm="9">
              <Form.Select
                size="sm"
                style={{ marginBottom: "3px" }}
                className="nodrag"
                value={dependency}
                onChange={(event) => onChangeDependency(event)}
              >
                <option key="select">-- Select --</option>
                {props.rules &&
                  props.rules.map((option) => (
                    <option key={option.ref} value={option.ref}>
                      {option.ref}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.onAddFilter(name, dependency)}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default AddFilterModal;
