import React from "react";
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import JsonForm from "./JsonForm";

function JsonModal(props) {
    //console.log(props);
    return (
        <>
            <Modal
                show={props.show}
                onHide={props.handleClose} size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.obj}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <JsonForm facts={props.facts} obj={props.obj} name={props.name} parent={props.parent}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    );
}

export default JsonModal;