
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import helper from "../helper";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";


const CodeEditor = (props) => {
    const [code, setCode] = useState('');
    useEffect(() => {
        var text = helper.generate(props.values);
        text = text + "export default {0};".format(helper.camelCase(props.values.name));
        setCode(text);
    }, [props.values])
    

    return (
        <>
            <Row>
                <Col>
                    <CodeMirror value={code}
                        height="50vh"
                        extensions={[javascript({ jsx: true })]}
                        readOnly='nocursor'
                    />
                </Col>
            </Row>
        </>
    )
}

export default CodeEditor;