import React from "react";
import Form from 'react-bootstrap/Form';

const SelectField = (props) => {
    return (
        <>
            <Form.Control as="select" name={`${props.name}`} 
            disabled={props.remote}
            size="sm" onChange={props.handleOnChange}
            value={props.value}
            onBlur={props.handleBlur} autoFocus
            >
                <option>Select...</option>
                {props.options && props.options.map((o, i) => {
                    return ( <option value={o}>{o}</option>)
                })}
            </Form.Control>
        </>
    )
}

export default SelectField;

