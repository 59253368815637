import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import dataTypes from "../../data/operator.json";
import SelectField from "../forms/select-field";
import InputsConditions from "../InputConditions";

const InputsForm = (props) => {
  const [inputs, setInputs] = useState(props.values.inputs);
  //console.log(inputs);

  const onArrayChange = (event, index) => {
    let temp = [...inputs];
    temp[index].array = props.values.inputs[index].array ? false : true;
    props.values.inputs = temp;
    setInputs(temp);
    //setAttributes([]);
  };

  const onNameChange = (event, index) => {
    let temp = [...inputs];
    temp[index].name = event.target.value;
    props.values.inputs = temp;
    setInputs(temp);
  };

  const onTypeChange = (event, index) => {
    let temp = [...inputs];
    temp[index].type = event.target.value;
    props.values.inputs = temp;
    setInputs(temp);
  };

  const addItem = () => {
    const newList = [...props.values.inputs, { name: "", type: "" }];
    props.values.inputs = newList;
    setInputs(newList);
  };

  const deleteItem = (index) => () => {
    let temp = [...inputs];
    temp = temp.filter((_, i) => index !== i);
    props.values.inputs = temp;
    setInputs(temp);
  };

  const attributesTypes = Object.keys(dataTypes);
  const objectFacts = props.values.facts.filter(
    (e) => e.type === "Object" && e.name !== ""
  );
  if (objectFacts.length > 0) {
    objectFacts.forEach((e) => attributesTypes.push(e.name));
  }

  return (
    <>
      <div style={{ position: "relative", padding: "12px" }} className="border">
        <Row className="border-bottom border-dark">
          <Col sm={10}>
            <span>{props.title}</span>
          </Col>
          <Col sm={2}>
            <Button onClick={addItem} variant="link" disabled={props.remote}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Col>
        </Row>
        {props.values.inputs &&
          props.values.inputs.map((inp, i) => {
            return (
              <div key={i} style={{ position: "relative", paddingTop: "12px" }}>
                <div>
                  <Row>
                    <Col sm={4}>
                      <Form.Control
                        type="text"
                        name={`${props.id}[${i}].name`}
                        value={inp.name}
                        disabled={props.remote}
                        placeholder={`Name`}
                        size="sm"
                        onChange={(event) => onNameChange(event, i)}
                      />
                    </Col>
                    <Col sm={3}>
                      <SelectField
                        name={`${props.id}[${i}].type`}
                        options={attributesTypes}
                        remote={props.remote}
                        value={inp.type}
                        handleOnChange={(event) => onTypeChange(event, i)}
                      />
                    </Col>

                    <Col sm={2}>
                      <Form.Check
                        disabled={props.remote}
                        type="checkbox"
                        name={`${props.id}[${i}].isarray`}
                        label="Array?"
                        onChange={(event) => onArrayChange(event, i)}
                      />
                    </Col>
                    <Col sm={1}>
                      <ButtonGroup>
                        <Button
                          variant="link"
                          onClick={deleteItem(i)}
                          disabled={props.remote}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </Button>
                      </ButtonGroup>
                    </Col>
                    <Col sm={2}></Col>
                  </Row>
                </div>
              </div>
            );
          })}
      </div>

      <InputsConditions
        values={props.values}
        title="Conditions"
        id="inputconditions"
        remote={props.remote}
        businessConditions={props.businessConditions}
      />
    </>
  );
};

export default InputsForm;
