/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import axios from "axios";
import LoadingSpinner from "../loading-spinner";
import "./brms.css";
import RuleViewer from "./RuleViewer";
import BRMSRuleView from "./BRMSRuleView";
import BRMSSummary from "./BRMSSummary";
//import { v4 as uuidv4 } from "uuid";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const BRMSMain = (props) => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [showView, setShowView] = useState("SUMMARY");
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedFeature, setSelectedFeature] = useState("");
  const [selectedRule, setSelectedRule] = useState("");
  const [filteredProjects, setFilteredProjects] = useState(projects || []);
  const [filterText, setFilterText] = useState("");
  const [rule, setRule] = useState({
    //id: "",
    name: "",
    description: "",
    type: "decision",
    facts: [],
    inputs: [],
    inputconditions: [],
    outcome: { name: "", type: "", attributes: [] },
    decisions: [],
    headers: [{ display: "", condition: "" }],
    testcases: [],
  });
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    setLoading(true);
    try {
      axios
        .get(`${BASE_URL}/api/business/rules`)
        .then((response) => {
          setLoading(false);
          console.log(response.data);
          setFilteredProjects(response.data);
          setProjects(response.data);
        })
        .catch((ex) => {
          console.log(ex);
          setLoading(false);
          setFilteredProjects([]);
          setProjects([]);
        });
    } catch (error) {
      console.log(error);
      setFilteredProjects([]);
      setLoading(false);
      setProjects([]);
    }
  }, []);

  const onChangeFilterText = (event) => {
    setFilterText(event.target.value);
  };

  const filterProjects = (text) => {
    let temp = projects.map((t) => {
      let prj = { ...t };
      prj.features =
        t.features &&
        t.features.map((f) => {
          let feature = { ...f };
          feature.rules =
            f.rules && f.rules.filter((r) => r.name.includes(text));
          return feature;
        });
      return prj;
    });
    return temp;
  };

  const setChangedProjects = (data) => {
    setProjects(data);
    setFilteredProjects(data);
  };

  const updateProjects = (pId, project) => {
    const prjs = [...projects];
    const index = projects.findIndex((el) => el._id === pId);
    prjs[index] = project;
    setProjects(prjs);
    setFilteredProjects(prjs);
  }

  const onClickSearch = () => {
    filterText
      ? setFilteredProjects(filterProjects(filterText))
      : setFilteredProjects(projects);
  };

  /*const fetchRule = (prj, feature, ruleId) => {
    let ruleObj = null;
    projects.forEach((p) => {
      if (p.name === prj) {
        p.features.forEach((f) => {
          if (f.name === feature) {
            f.rules.forEach((r) => {
              if (r.id === ruleId) {
                ruleObj = r;
              }
            });
          }
        });
      }
    });
    return ruleObj;
  };*/

  const onSelectRule = (prj, feature, ruleId, repoUrl) => {
    setRule({
      //id: "",
      name: "",
      description: "",
      type: "decision",
      facts: [],
      inputs: [],
      inputconditions: [],
      outcome: { name: "", type: "", attributes: [] },
      decisions: [],
      headers: [{ display: "", condition: "" }],
      testcases: [],
    });
    setLoading(true);
    setSelectedProject(prj);
    setSelectedFeature(feature);
    setSelectedRule(ruleId);

    setLoading(true);
    axios
      .get(
        `${BASE_URL}/api/business/projects/${prj}/features/${feature}/rules/${ruleId}`
      )
      .then((response) => {
        const r = response.data;
        if (r.origin === "remote") {
          let settings = localStorage.getItem("settings");
          if (settings) {
            axios
              .post(`${BASE_URL}/api/git/rule/content`, {
                url: repoUrl,
                token: settings.token,
                path: r.path,
              })
              .then((response) => {
                console.log(response.data);
                let tempRule = response.data;
                tempRule["origin"] = "remote";
                tempRule.id = r.id;
                tempRule.name = r.name;
                setRule(tempRule);
                setShowView("RULE");
                setLoading(false);
              })
              .catch((ex) => {
                console.log(ex);
                setRule(r);
                setShowView("RULE");
                setLoading(false);
              });
          }
        } else {
          let tempRule = { ...r };
          setRule(tempRule);
          setShowView("RULE");
          setLoading(false);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
    /*let myProjects = localStorage.getItem("myProjects");
    if (myProjects) {
      myProjects = JSON.parse(myProjects);
      myProjects.forEach((p) => {
        if (p.name === selectedProject) {
          p.features.forEach((f) => {
            if (f.name === selectedFeature) {
              f.rules.forEach((r) => {
                if (r.id === ruleId) {
                  if (r.origin === "remote") {
                    let settings = localStorage.getItem("settings");
                    if (settings) {
                      axios
                        .post(`${BASE_URL}/api/git/rule/content`, {
                          url: p.url,
                          token: settings.token,
                          path: r.path,
                        })
                        .then((response) => {
                          console.log(response.data);
                          let tempRule = response.data;
                          tempRule["origin"] = "remote";
                          tempRule.id = r.id;
                          tempRule.name = r.name;
                          setRule(tempRule);
                          setShowView("RULE");
                          setLoading(false);
                        })
                        .catch((ex) => {
                          console.log(ex);
                          setRule(r);
                          setShowView("RULE");
                          setLoading(false);
                        });
                    }
                  } else {
                    let tempRule = { ...r };
                    setRule(tempRule);
                    setShowView("RULE");
                    setLoading(false);
                  }
                }
              });
            }
          });
        }
      });
    }*/
  };

  const showConfirmationBox = (prj, feature, ruleId, ruleName) => {
    //let ruleObj = fetchRule(prj, feature, ruleId);
    //let message = `Are you sure to delete selected rule?`;
    //if (ruleObj) {
    let message = `Are you sure to delete rule [${ruleName}]?`;
    //}

    confirmAlert({
      //title: "Confirm to delete",
      message: message,
      buttons: [
        {
          label: "Yes",
          onClick: () => onClickDeleteRule(prj, feature, ruleId),
        },
        {
          label: "No",
          onClick: () => console.log("Do not delete"),
        },
      ],
    });
  };

  const showDeleteProjectConfirmation = (prj, pname) => {
    confirmAlert({
      //title: "Confirm to delete",
      message: `Are you sure to delete project [${pname}].`,
      buttons: [
        {
          label: "Yes",
          onClick: () => onClickDeleteProject(prj),
        },
        {
          label: "No",
          onClick: () => console.log("Do not delete"),
        },
      ],
    });
  };

  const showDeleteFeatureConfirmation = (prj, feature, fname) => {
    confirmAlert({
      //title: "Confirm to delete",
      message: `Are you sure to delete feature [${fname}].`,
      buttons: [
        {
          label: "Yes",
          onClick: () => onClickDeleteFeature(prj, feature),
        },
        {
          label: "No",
          onClick: () => console.log("Do not delete"),
        },
      ],
    });
  };

  const onClickDeleteRule = (prj, feature, ruleId) => {
    setLoading(true);
    axios
      .delete(
        `${BASE_URL}/api/business/projects/${prj}/features/${feature}/rules/${ruleId}`
      )
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        const prjs = [...projects];
        //const tempPrj = prjs.find((p) => p._id === prj);
        //const testFeature = tempPrj.features.find((p) => p._id === prj);
        //testFeature.rules = testFeature.rules.filter(r => r._id !== ruleId);
        let currProject = prjs.find((p) => p._id === prj) || null;
        if (currProject) {
          let currFeature =
            currProject.features.find((f) => f._id === feature) || null;
          if (currFeature) {
            currFeature.rules = currFeature.rules.filter(
              (r) => r._id !== ruleId
            );
          }
        }
        setShowView("SUMMARY");
        setFilteredProjects(prjs);
        setProjects(prjs);
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
    /*let myProjects = localStorage.getItem("myProjects");
    if (myProjects) {
      myProjects = JSON.parse(myProjects);
      let currProject = myProjects.find((p) => p.name === prj) || null;
      if (currProject) {
        let currFeature =
          currProject.features.find((f) => f.name === feature) || null;
        if (currFeature) {
          currFeature.rules = currFeature.rules.filter((r) => r.id !== ruleId);
        }
      }
      setShowView("SUMMARY");
      setProjects(myProjects);
      setFilteredProjects(myProjects);
      localStorage.setItem("myProjects", JSON.stringify(myProjects));
    }*/
  };

  const onClickDeleteProject = (prj) => {
    setLoading(true);
    axios
      .delete(`${BASE_URL}/api/business/rules/projects/${prj}`)
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        const prjs = projects.filter((p) => p._id !== prj);
        setFilteredProjects(prjs);
        setProjects(prjs);
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };

  const onClickDeleteFeature = (prj, feature) => {
    setLoading(true);
    axios
      .delete(
        `${BASE_URL}/api/business/rules/projects/${prj}/features/${feature}`
      )
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        const prjs = [...projects];
        const tempPrj = prjs.find((p) => p._id === prj);
        tempPrj.features = tempPrj.features.filter((f) => f._id !== feature);
        setFilteredProjects(prjs);
        setProjects(prjs);
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };

  const onClickAddRule = (pId, prj, fId, feature) => {
    setSelectedProject(pId);
    setSelectedFeature(fId);
    setShowView("RULE");
  };

  const onClickCheckout = () => {
    let myProjects = localStorage.getItem("myProjects");
    if (myProjects) {
      myProjects = JSON.parse(myProjects);
      myProjects.forEach((p) => {
        if (p.name === selectedProject) {
          p.features.forEach((f) => {
            if (f.name === selectedFeature) {
              f.rules.forEach((r, i) => {
                if (r.id === rule.id) {
                  rule.origin = "local";
                  f.rules[i] = rule;
                }
              });
            }
          });
        }
      });
      setProjects(myProjects);
      setFilteredProjects(myProjects);
      localStorage.setItem("myProjects", JSON.stringify(myProjects));
    }
  };

  

  const onSaveRule = () => {
    setLoading(true);
    axios
      .post(
        `${BASE_URL}/api/business/projects/${selectedProject}/features/${selectedFeature}/rules`,
        rule
      )
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        const prjs = [...projects];
        prjs.forEach((p) => {
          if (p._id === selectedProject) {
            p.features.forEach((f) => {
              if (f._id === selectedFeature) {
                if (rule._id) {
                  f.rules.forEach((r, i) => {
                    if (r._id === rule._id) {
                      f.rules[i] = rule;
                    }
                  });
                } else {
                  f.rules.push(response.data);
                }
              }
            });
          }
        });
        setFilteredProjects(prjs);
        setProjects(prjs);
        setRule({
          name: "",
          description: "",
          type: "decision",
          facts: [],
          inputs: [],
          inputconditions: [],
          outcome: { name: "", type: "", attributes: [] },
          decisions: [],
          headers: [{ display: "", condition: "" }],
          testcases: [],
        });
        setShowView("SUMMARY");
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
    /*let myProjects = localStorage.getItem("myProjects");
    if (myProjects) {
      myProjects = JSON.parse(myProjects);
      myProjects.forEach((p) => {
        if (p.name === selectedProject) {
          p.features.forEach((f) => {
            if (f.name === selectedFeature) {
              if (rule.id) {
                f.rules.forEach((r, i) => {
                  if (r.id === rule.id) {
                    f.rules[i] = rule;
                  }
                });
              } else {
                rule.id = uuidv4();
                f.rules.push(rule);
              }
            }
          });
        }
      });
      setProjects(myProjects);
      setFilteredProjects(myProjects);
      setRule({
        name: "",
        description: "",
        type: "decision",
        id: "",
        facts: [],
        inputs: [],
        inputconditions: [],
        outcome: { name: "", type: "", attributes: [] },
        decisions: [],
        headers: [{ display: "", condition: "" }],
        testcases: [],
      });
      setShowView("SUMMARY");
      localStorage.setItem("myProjects", JSON.stringify(myProjects));
    } else {
      /*axios
        .post(
          `${BASE_URL}/api/rules/business/projects/${selectedProject}/features/${selectedFeature}/rules`,
          rule
        )
        .then((response) => {
          setRule({
            name: "",
            description: "",
            type: "decision",
            facts: [],
            inputs: [],
            inputconditions: [],
            outcome: { name: "", type: "", attributes: [] },
            decisions: [],
            headers: [{ display: "", condition: "" }],
            testcases: [],
          });
          setProjects(response.data);
          setFilteredProjects(response.data);
          localStorage.setItem("myProjects", response.data);
          setShowView("SUMMARY");
        })
        .catch((ex) => {
          setRule({
            name: "",
            description: "",
            type: "decision",
            facts: [],
            inputs: [],
            inputconditions: [],
            outcome: { name: "", type: "", attributes: [] },
            decisions: [],
            headers: [{ display: "", condition: "" }],
            testcases: [],
          });
          console.log(ex);
        });*/
    //}
  };

  return (
    <>
      <Container fluid>
        {loading && <LoadingSpinner />}
        <Row className="brms-left-row">
          <Col sm="3" className="brms-left">
            <Row className="mt-2">
              <Col sm="12" className="brms-header">
                <span className="brms-underline">
                  Business Rules Management System
                </span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col sm="12">
                <InputGroup>
                  <Form.Control
                    placeholder="Search Rules..."
                    onChange={(event) => onChangeFilterText(event)}
                  />
                  <InputGroup.Text id="rule-search">
                    <FontAwesomeIcon
                      icon={faSearch}
                      onClick={() => onClickSearch()}
                    />
                  </InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
            <hr />
            <Row className="mt-2">
              <Col sm="12">
                <RuleViewer
                  projects={filteredProjects}
                  selectedProject={selectedProject}
                  selectedFeature={selectedFeature}
                  selectedRule={selectedRule}
                  setSelectedProject={setSelectedProject}
                  setSelectedFeature={setSelectedFeature}
                  setSelectedRule={setSelectedRule}
                  setProjects={setChangedProjects}
                  onSelectRule={onSelectRule}
                  onClickAddRule={onClickAddRule}
                  onDelete={showConfirmationBox}
                  onDeleteProject={showDeleteProjectConfirmation}
                  onDeleteFeature={showDeleteFeatureConfirmation}
                  updateProjects={updateProjects}
                />
              </Col>
            </Row>
          </Col>
          {showView && (
            <Col sm="9">
              {showView === "RULE" && (
                <BRMSRuleView
                  key={rule.id}
                  showView={showView}
                  rule={rule}
                  onClickCancel={() => setShowView("SUMMARY")}
                  onClickCheckout={onClickCheckout}
                  onSaveRule={onSaveRule}
                  selectedProject={selectedProject}
                  selectedFeature={selectedFeature}
                />
              )}
              {showView === "SUMMARY" && (
                <BRMSSummary
                  projects={filteredProjects}
                  onSelectRule={onSelectRule}
                  onDelete={showConfirmationBox}
                />
              )}
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};
export default BRMSMain;
