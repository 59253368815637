/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCaretDown,
  faCaretUp,
  faGlobe,
  faHome,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import LoadingSpinner from "./loading-spinner";
import PresentationRuleModal from "./PresentationRuleModal";
import AddProject from "./prms/AddProject";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const PresentationRuleViewer = (props) => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [showAddProject, setShowAddProject] = useState(false);
  const [showAddFeature, setShowAddFeature] = useState(false);
  const [showRuleModal, setShowRuleModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedFeature, setSelectedFeature] = useState("");
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    setLoading(true);
    try {
      axios
        .get(`${BASE_URL}/api/prms/projects`)
        .then((response) => {
          setLoading(false);
          console.log(response.data);
          setProjects(response.data);
        })
        .catch((ex) => {
          console.log(ex);
          setLoading(false);
          setProjects([]);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      setProjects([]);
    }
  }, []);

  const showDeleteConfirmation = (message, action) => {
    confirmAlert({
      message: message,
      buttons: [
        {
          label: "Yes",
          onClick: action,
        },
        {
          label: "No",
          onClick: () => console.log("Do not delete"),
        },
      ],
    });
  };

  const onClickDeleteProject = (prj) => {
    setLoading(true);
    axios
      .delete(`${BASE_URL}/api/prms/projects/${prj}`)
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        const prjs = projects.filter((p) => p._id !== prj);
        setProjects(prjs);
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };

  const onClickDeleteFeature = (prj, feature) => {
    setLoading(true);
    axios
      .delete(
        `${BASE_URL}/api/prms/projects/${prj}/features/${feature}`
      )
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        const prjs = [...projects];
        const index = projects.findIndex((el) => el._id === prj);
        prjs[index] = response.data;
        setProjects(prjs);
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };

  const onClickDeleteRule = (prj, feature, ruleId, pId, fId) => {
    console.log(prj, feature, ruleId);
    setLoading(true);
    axios
      .delete(
        `${BASE_URL}/api/prms/projects/${pId}/features/${fId}/rules/${ruleId}`
      )
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        const prjs = [...projects];
        const index = projects.findIndex((el) => el._id === pId);
        prjs[index] = response.data;
        setProjects(prjs);
        props.setRule(
          prj,
          feature,
          '',
          '',
          '',
          true,
          pId,
          fId
        );
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };

  const onBlurFeature = (event, prj, pId) => {
    setLoading(true);
    axios
      .post(
        `${BASE_URL}/api/prms/projects/${pId}/features`,
        { name: event.target.value, rules: [] }
      )
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        const prjs = [...projects];
        const index = projects.findIndex((el) => el._id === pId);
        prjs[index] = response.data;
        setProjects(prjs);
        setShowAddFeature(false);
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
    
  };
  const onSaveRule = (prj, feature, rulename) => {
    setLoading(true);
    axios
      .post(
        `${BASE_URL}/api/prms/projects/${prj}/features/${feature}/rules`,
        { name: rulename, origin: "local" }
      )
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        const prjs = [...projects];
        const index = projects.findIndex((el) => el._id === prj);
        prjs[index] = response.data;
        setProjects(prjs);
        setShowRuleModal(false);
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };
  const onClickAddRule = (prj, feature, pId, fId) => {
    setSelectedProject(pId);
    setSelectedFeature(fId);
    setShowRuleModal(true);
  };

  const onSelectRule = (prj, feature, ruleId, pId, fId) => {
    setSelectedProject(pId);
    setSelectedFeature(fId);
    const project = projects.find(p => p._id === pId);

    setLoading(true);
    axios
      .get(
        `${BASE_URL}/api/prms/projects/${prj}/features/${feature}/rules/${ruleId}`
      )
      .then((response) => {
        const r = response.data;
        if (r.origin === "remote") {
          let settings = localStorage.getItem("settings");
          if (settings) {
            axios
              .post(`${BASE_URL}/api/git/rule/content`, {
                url: project.url,
                token: settings.token,
                path: r.path,
              })
              .then((response) => {
                console.log(response.data);
                props.setRule(
                  prj,
                  feature,
                  r.name,
                  response.data,
                  r._id,
                  true,
                  pId,
                  fId
                );
                setLoading(false);
              })
              .catch((ex) => {
                console.log(ex);
                props.setRule(prj, feature, r.name, "", r._id, true, pId, fId);
                setLoading(false);
              });
          }
        } else {
          props.setRule(prj, feature, r.name, r.code, r._id, false,  pId, fId);
          setLoading(false);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });

    
  };
  return (
    <>
      {showAddProject && (
        <AddProject
          show={showAddProject}
          setProjects={setProjects}
          handleClose={() => setShowAddProject(false)}
        />
      )}
      {showRuleModal && (
        <PresentationRuleModal
          project={selectedProject}
          feature={selectedFeature}
          show={showRuleModal}
          handleClose={() => setShowRuleModal(false)}
          onSave={onSaveRule}
        />
      )}
      <Row>
        <Col>
          <span>PROJECTS</span>
          <FontAwesomeIcon
            icon={faPlus}
            style={{ float: "right" }}
            onClick={() => setShowAddProject(true)}
          />
          <hr />
        </Col>
      </Row>
      {loading && <LoadingSpinner />}
      <Row>
        <Col sm="12">
          {projects &&
            projects.map((p, pi) => {
              return (
                <>
                  <Row>
                    <Col sm="12">
                      {selectedProject === p._id ? (
                        <FontAwesomeIcon
                          icon={faCaretUp}
                          onClick={() => setSelectedProject("")}
                          style={{ paddingRight: "10px" }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCaretDown}
                          onClick={() => setSelectedProject(p._id)}
                          style={{ paddingRight: "10px" }}
                        />
                      )}
                      <span>{p.name}</span>
                      <FontAwesomeIcon
                        icon={faPlus}
                        style={{ float: "right", paddingLeft: "5px" }}
                        onClick={() => setShowAddFeature(p._id)}
                      />
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        style={{ float: "right" }}
                        onClick={() =>
                          showDeleteConfirmation(
                            `Are you sure to delete project [${p.name}].`,
                            () => onClickDeleteProject(p._id)
                          )
                        }
                      />
                      <hr />
                    </Col>
                  </Row>
                  {showAddFeature === p._id && (
                    <Row>
                      <Col sm="3">
                        <Form.Label>Name</Form.Label>
                      </Col>
                      <Col sm="9">
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="feature name..."
                          onBlur={(event) => onBlurFeature(event, p.name, p._id)}
                        />
                      </Col>
                    </Row>
                  )}
                  {selectedProject === p._id &&
                    p.features &&
                    p.features.map((f, fi) => {
                      return (
                        <>
                          <Row>
                            <Col sm="2">
                              {selectedProject === p._id &&
                              selectedFeature === f._id ? (
                                <FontAwesomeIcon
                                  icon={faCaretUp}
                                  onClick={() => setSelectedFeature("")}
                                  style={{ float: "right" }}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faCaretDown}
                                  onClick={() => setSelectedFeature(f._id)}
                                  style={{ float: "right" }}
                                />
                              )}
                            </Col>
                            <Col sm="10">
                              <span>{f.name}</span>
                              <FontAwesomeIcon
                                icon={faPlus}
                                style={{ float: "right", paddingLeft: "5px" }}
                                onClick={() =>
                                  onClickAddRule(p.name, f.name, p._id, f._id)
                                }
                              />
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                style={{ float: "right" }}
                                onClick={() =>
                                  showDeleteConfirmation(
                                    `Are you sure to delete feature [${p.name} / ${f.name}].`,
                                    () => onClickDeleteFeature(p._id, f._id)
                                  )
                                }
                              />
                              <hr />
                            </Col>
                          </Row>
                          {selectedFeature === f._id &&
                            f.rules &&
                            f.rules.map((r, ri) => {
                              return (
                                <>
                                  <Row>
                                    <Col sm="3">
                                      <FontAwesomeIcon
                                        icon={
                                          r.origin === "remote"
                                            ? faGlobe
                                            : faHome
                                        }
                                        style={{ float: "right" }}
                                        title={
                                          r.origin === "remote"
                                            ? "remote"
                                            : "local"
                                        }
                                        color="blue"
                                      />
                                    </Col>

                                    <Col
                                      sm="9"
                                      onClick={() =>
                                        onSelectRule(
                                          p.name,
                                          f.name,
                                          r._id,
                                          p._id,
                                          f._id
                                        )
                                      }
                                    >
                                      <span>{r.origin}-{r.name}</span>
                                      <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        style={{ float: "right" }}
                                        onClick={() =>
                                          showDeleteConfirmation(
                                            `Are you sure to delete rule [${p.name} / ${f.name} / ${r.name}].`,
                                            () =>
                                              onClickDeleteRule(
                                                p.name,
                                                f.name,
                                                r._id,
                                                p._id,
                                                f._id
                                              )
                                          )
                                        }
                                      />
                                      <hr />
                                    </Col>
                                  </Row>
                                </>
                              );
                            })}
                        </>
                      );
                    })}
                </>
              );
            })}
        </Col>
      </Row>
    </>
  );
};
export default PresentationRuleViewer;
