// src/components/ProfileImage.js
import React from "react";
import "./ProfileImage.css"; // Import your CSS file for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

const ProfileImage = (props) => {
  
  return (
    
      <>
        {props.imageUrl ? (
          <img
            src={props.imageUrl}
            alt={"Profile"}
            className="circular-image"
          />
        ) : (
          <FontAwesomeIcon
            icon={faUserCircle}
            className="circular-icon"
            style={{ color: "white", width: "40px", height: "40px" }}
          />
        )}
      </>
  );
};

export default ProfileImage;
