import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useState } from "react";
import { Card } from "react-bootstrap";
import { Handle, Position } from "reactflow";
import SpanToInputElementMaker from "./SpanToInputElementMaker";

function Page({ id, data }) {
  const [name, setName] = useState(data.name ? data.name : "Page");
  const [show, setShow] = useState(false);
  const onChangeName = (event) => {
    setName(event.target.value);
    data.name = event.target.value;
  };

  return (
    <>
      <Card>
        <Card.Body
          className="custom-node__header"
          style={{ textAlign: "center" }}
        >
          <Card.Title style={{ marginBottom: 0 }}>
            <FontAwesomeIcon
              icon={faGlobe}
              style={{ paddingTop: "0.2rem", color: "#460073" }}
            />
            {/*<div>
              <span style={{ fontSize: "10px", color: "#460073" }}>
                <strong>Page</strong>
              </span>
  </div>*/}
          </Card.Title>
          <Handle type="source" position={Position.Right} id={id} />
          <Handle type="target" position={Position.Left} id={id} />
        </Card.Body>
      </Card>
      <div style={{textAlign:"center"}} className="textWrap">
      <SpanToInputElementMaker
        name="value"
        value={name}
        className="textwrap"
        spanvalue={name}
        handleChange={(e) => {
          onChangeName(e);
        }}
        handleDoubleClick={() => setShow(true)}
        handleBlur={() => setShow(false)}
        showInputEle={show}
      />
      </div>
    </>
  );
}

export default memo(Page);
