import { useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
} from "react-bootstrap";

const PresentationRuleModal = (props) => {
  const [name, setName] = useState('');
  const onChangeName = (event) => { 
    setName(event.target.value);
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Rule...</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm="3">
            <Form.Label>Name</Form.Label>
          </Col>
          <Col sm="9">
            <Form.Control
              size="sm"
              type="text"
              placeholder="Rule name..."
              value={name}
              onChange={(event) => onChangeName(event)}
            />
          </Col>
        </Row>
        
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => props.onSave(props.project, props.feature, name)}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default PresentationRuleModal;
