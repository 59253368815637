/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-new-func */
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tab,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import { javascript } from "@codemirror/lang-javascript";
import CodeMirror from "@uiw/react-codemirror";
import axios from "axios";

const ViewUI = (props) => {
  const BASE_URL = process.env.REACT_APP_BACKEND_URL;
  const [code, setCode] = useState("");
  const [rules, setRules] = useState([]);
  const [results, setResults] = useState([]);
  const [showJS, setShowJS] = useState(false);
  const [showUI, setShowUI] = useState(true);

  const configKey = props.code && Object.keys(props.code).length > 0 ? Object.keys(props.code)[0] : '';
  const pageKey = configKey ? Object.keys(props.code[configKey]).find(k => !['dependencyInfo', 'filterInfo'].includes(k)) || '' : '';
  const pages =  pageKey ? props.code[configKey][pageKey] : [];
  console.log(configKey, pageKey);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/business/rules/code`)
      .then((response) => {
        console.log(response.data);
        setRules(response.data);
      })
      .catch((ex) => {
        console.log(ex);
      });
  }, []);

  const onValidate = () => {
    const ruleCode = rules.map((r) => {
      return `const ${r.name} = ${r.code};`;
    }).join('\n');
    const validationResults = rules.map((r) => {
      const fnCode = `${ruleCode}\nreturn ${r.name}`
      //const fnCode = `const alwaysTrue = function (){ return true ;}\nreturn alwaysTrue`;
      const fn = new Function(fnCode)();
      console.log(fn);
      //console.log(fn(2));
      return { name: r.name, code: r.code, result: fn(JSON.parse(code)) };
    });
    console.log(validationResults);
    setResults(validationResults);
  };

  const evaluateRule = (dependencyKey) => {
    const dependency = results.find((v) => v.name === dependencyKey) || null;
    console.log(dependencyKey, dependency);
    return dependency && dependency.result;
  };

  const evaluateSetValue = (q) => {
    if (q.setValue) {
      if (evaluateRule(q.setValue.dependencyKey.ref)) {
        return true;
      }
    }
    return false;
  };

  const evaluateEnabled = (q) => {
    if (q.enabled) {
      if (evaluateRule(q.enabled.dependencyKey.ref)) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  };

  const evaluateFilter = (q, filters, value) => {
    if (q.filterList) {
      let res = true;
      q.filterList.forEach((f) => {
        let filterInfo = filters.find((fi) => fi.ref === f.ref) || null;
        if (
          filterInfo &&
          filterInfo["filter-item"] &&
          filterInfo["filter-item"].includes(value)
        ) {
          if (!evaluateRule(f.dependencyKey.ref)) {
            res = false;
          }
        }
        
      });
      return res;
    }
    return true;
  };

  const showTab = (tab) => {
    if (tab === 'ui') {
      setShowJS(false);
      setShowUI(true);
    } else {
      setShowJS(true);
      setShowUI(false);
    }
  }


  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Test Presentation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showJS && <Row>
          <Col sm="12" style={{ fontSize: "12px" }}>
            <CodeMirror
              value={rules.map((r) => {
                return `const ${r.name} = ${r.code}`;
              }).join('\n')}
              height="380px"
              extensions={[javascript({ jsx: true })]}
            />
            <Button variant="outline-danger" onClick={() => showTab('ui')} size="sm">
              View UI
            </Button>
          </Col></Row>}
        {showUI && <Row>
          <Col sm="4" style={{ fontSize: "12px" }}>
            <CodeMirror
              value={code}
              height="380px"
              extensions={[javascript({ jsx: true })]}
              onChange={(editor, metadata, value) => {
                setCode(editor);
              }}
            />
            <Button variant="outline-danger" onClick={() => onValidate()} size="sm">
              Validate
            </Button>{' '}
            <Button variant="outline-danger" onClick={() => showTab('js')} size="sm">
              View Javascript
            </Button>
          </Col>
          <Col sm="8">
            <Tabs
              defaultActiveKey="0"
              id="fill-tab-example"
              className="mb-3"
              style={{ fontSize: "12px" }}
            >
              
              {
              
              pages.map((p, i) => {
                  return (
                    <Tab eventKey={i} title={`Page-${i}`} style={{fontSize:"12px"}}>
                      {p.question.map((q, j) => {
                        return (
                          <>
                            {!(
                              q.visible &&
                              q.visible.dependencyKey &&
                              evaluateRule(q.visible.dependencyKey.ref)
                            ) && (
                              <Row>
                                <Col sm="6">
                                  <OverlayTrigger
                                    overlay={
                                      q.tooltip ? (
                                        <Tooltip>
                                          {q.tooltip}
                                        </Tooltip>
                                      ) : (
                                        <Tooltip></Tooltip>
                                      )
                                    }
                                  >
                                    <Form.Label style={{ fontSize: "12px" }}>
                                      {q.label}
                                      {q.required &&
                                      q.required.dependencyKey &&
                                      evaluateRule(q.required.dependencyKey.ref)
                                        ? "*"
                                        : ""}
                                    </Form.Label>
                                  </OverlayTrigger>
                                </Col>
                                <Col sm="6" style={{ fontSize: "12px" }}>
                                  {q.answerDropDown && (
                                    <Form.Select
                                      size="sm"
                                      value={
                                        evaluateSetValue(q)
                                          ? q.setValue.value
                                          : q.default
                                          ? q.default.value
                                          : ""
                                      }
                                      disabled={evaluateEnabled(q)}
                                    >
                                      <option>--Select--</option>
                                      {q.answerDropDown.option
                                        .filter((o) =>
                                          evaluateFilter(
                                            q,
                                            props.code[configKey]
                                              .filterInfo
                                              ? props.code[configKey]
                                                  .filterInfo.filter
                                              : [],
                                            o.value.code
                                          )
                                        )
                                        .map((o) => {
                                          return (
                                            <option value={o.value.code}>
                                              {o.value.decode}
                                            </option>
                                          );
                                        })}
                                    </Form.Select>
                                  )}

                                  {q.answerRadio &&
                                    q.answerRadio.option.filter((o) =>
                                    evaluateFilter(
                                      q,
                                      props.code[configKey]
                                        .filterInfo
                                        ? props.code[configKey]
                                            .filterInfo.filter
                                        : [],
                                      o.value.code
                                    )
                                  ).map((o) => {
                                      return (
                                        <Form.Check
                                          type="radio"
                                          label={o.value.decode}
                                          disabled={evaluateEnabled(q)}
                                          checked={
                                            evaluateSetValue(q)
                                              ? q.setValue.value ===
                                                o.value.code
                                              : q.default
                                              ? q.default.value === o.value.code
                                              : false
                                          }
                                        />
                                      );
                                    })}
                                  {q.answerCheckBox &&
                                    q.answerCheckBox.option.filter((o) =>
                                    evaluateFilter(
                                      q,
                                      props.code[configKey]
                                        .filterInfo
                                        ? props.code[configKey]
                                            .filterInfo.filter
                                        : [],
                                      o.value.code
                                    )
                                  ).map((o) => {
                                      return (
                                        <Form.Check
                                          type="check"
                                          label={o.value.decode}
                                          disabled={evaluateEnabled(q)}
                                          checked={
                                            evaluateSetValue(q)
                                              ? q.setValue.value ===
                                                o.value.code
                                              : q.default
                                              ? q.default.value === o.value.code
                                              : false
                                          }
                                        />
                                      );
                                    })}
                                  {q.answerTextBox && (
                                    <Form.Control
                                      size="sm"
                                      type="text"
                                      disabled={evaluateEnabled(q)}
                                      value={
                                        evaluateSetValue(q)
                                          ? q.setValue.value
                                          : q.default
                                          ? q.default.value
                                          : ""
                                      }
                                      placeholder={`${q.answerTextBox.dataType}`}
                                    />
                                  )}
                                </Col>
                              </Row>
                            )}
                            <hr />
                          </>
                        );
                      })}
                    </Tab>
                  );
                })}
            </Tabs>
          </Col>
        </Row>}
      </Modal.Body>
    </Modal>
  );
};
export default ViewUI;
