import React, { useState } from "react";
import { Row, Col, Button, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faPlay, faPlus } from '@fortawesome/free-solid-svg-icons'
import helper from "../helper";
import JsonModal from "./JsonModal";

function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
        return false;
    }
    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }
    return true;
}
function isObject(object) {
    return object != null && typeof object === 'object';
}

const TestRule = (props) => {
    const [testcases, setTestcases] = useState(props.values.testcases);
    //const [value, setValue] = useState('');
    //const [testResults, setTestResults] = useState([]);
    //const [attributes, setAttributes] = useState([]);


    const onInputCellChange = (event, r_index, c_index) => {
        let temp = [...testcases];
        temp[r_index].inputs[c_index].value = event.target.value;
        props.values.testcases = temp;
        setTestcases(temp);
    }

    const onOutcomeCellChange = (event, r_index) => {
        //alert(r_index);
        let temp = [...testcases];
        temp[r_index].result.value = event.target.value;
        props.values.testcases = temp;
        setTestcases(temp);
    }

    const executeAllTestCase = () => {
        var code = helper.generate(props.values);
        code = code + "export default {0};".format(helper.camelCase(props.values.name));
        console.log(code);
        const file = new File([code], "component.js", {
            type: "application/javascript",
        })
        const url = URL.createObjectURL(file)
        import(/* webpackIgnore: true */url).then(component => {
            let temp = [...testcases];
            temp.forEach(tc => {
                let values = tc.inputs.map(e => {
                    return helper.isObject(e.type) ? JSON.parse(e.value) : e.value
                });
                console.log(component.default(...values));
                let result = tc.result.value;
                
                switch (tc.result.type) {
                    case 'Number':
                        result = Number(result);
                        break;
                    case 'Double':
                        result = parseFloat(result);
                        break;
                    default:
                        break;
                }


                if (helper.isObject(tc.result.type)) {
                    result = JSON.parse(result);
                    tc['status'] = deepEqual(result, component.default(...values) ) ? 'Pass' : 'Fail';
                } else {
                    tc['status'] = result === component.default(...values) ? 'Pass' : 'Fail';
                }

                
                //setAttributes([]);
            })
            setTestcases(temp);
        })

        //setTestResults(props.values.testcases);
        //setAttributes([]);
    }

    const addItem = () => {
        const newList = [...props.values.testcases];
        let newEntry = { inputs: [], result: '', status: '' }
        props.values.inputs.forEach(h => {
            newEntry.inputs.push({
                type: h.type, name: h.name, value: ''
            });
        })
        if (props.values.outcome.name !== '') {
            newEntry.result = {
                name: props.values.outcome.name,
                type: props.values.outcome.type, value: ''
            }
        }
        newList.push(newEntry);
        props.values.testcases = newList;
        setTestcases(props.values.testcases);
        //setValue('');
    }

    const [show, setShow] = useState(false);
    const [outcomeShow, setOutcomeShow] = useState(false);
    let testData = {}
    //const handleClose = () => 
    const handleShow = (rindex, cindex) => {
        //alert('hi')
        testData = props.values.testcases[rindex].inputs[cindex].value;
        //console.log(testData)
        //setValue('');
        setShow(true);
    }

    const handleOutcomeShow = (rindex) => {
        testData = props.values.testcases[rindex].result.value;
        //setValue('');
        setOutcomeShow(true);
    }


    const handleClose = (rindex, cindex) => {
        props.values.testcases[rindex].inputs[cindex].value = JSON.stringify(testData);
        setShow(false);
        //testData = {};
    }

    const handleOutcomeClose = (rindex) => {
        //alert(rindex);
        props.values.testcases[rindex].result.value = JSON.stringify(testData);
        setOutcomeShow(false);
        //testData = {};
    }

    return (
        <>
            <div style={{ position: 'relative', padding: '12px' }} className="border">
                <Row className="border-bottom border-dark">
                    <Col sm={10}>{props.title}</Col>
                    <Col sm={2}>
                        <Button onClick={addItem} variant="link" disabled={props.remote}>
                            <FontAwesomeIcon icon={faPlus} />{props.remote}
                        </Button>
                        <Button onClick={executeAllTestCase} variant="link" disabled={props.remote}>
                            <FontAwesomeIcon icon={faPlay} />
                        </Button>
                    </Col>
                </Row>

                <Row className="justify-content-md-center">
                    <Table striped bordered hover style={{ width: '70%' }}>
                        <thead>
                            <tr>
                                {
                                    props.values.inputs.map((inp, i) => {
                                        return (
                                            <th>
                                                {helper.camelToSentenceCase(inp.name)}
                                            </th>
                                        );
                                    })
                                }
                                {props.values.outcome.name && <th>{props.values.outcome.name}</th>}
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.values.testcases && props.values.testcases.map((tc, i) => {
                                    return (
                                        <tr key={i} className="mb-2" style={{ position: 'relative', paddingTop: '12px' }}>
                                            {
                                                props.values.inputs.map((inp, j) => {
                                                    return (
                                                        <td>
                                                            {
                                                                helper.isObject(inp.type) ?
                                                                    <>
                                                                        <textarea
                                                                        disabled={props.remote}
                                                                            value={props.values.testcases[i].inputs[j].value}
                                                                            onChange={(e) => onInputCellChange(e, i, j)}
                                                                        />
                                                                        <Button onClick={() => handleShow(i, j)} variant="link" disabled={props.remote}>
                                                                            <FontAwesomeIcon icon={faGear} />
                                                                        </Button>
                                                                        {show && <JsonModal remote={props.remote} show={show} handleClose={() => handleClose(i, j)}
                                                                            obj={inp.type} name={''} facts={props.values.facts} parent={testData} />}
                                                                    </>
                                                                    :
                                                                    <input
                                                                    disabled={props.remote}
                                                                        type="text"
                                                                        value={props.values.testcases[i].inputs[j].value}
                                                                        onChange={(e) => onInputCellChange(e, i, j)}
                                                                    />



                                                            }




                                                        </td>
                                                    )
                                                })
                                            }
                                            {
                                                props.values.outcome.name &&
                                                <td>
                                                    {helper.isObject(props.values.outcome.type) ?
                                                        <>
                                                            <textarea
                                                            disabled={props.remote}
                                                                value={props.values.testcases[i].result.value}
                                                                onChange={(e) => onOutcomeCellChange(e, i)}
                                                            />
                                                            <Button onClick={() => handleOutcomeShow(i)} variant="link" disabled={props.remote}>
                                                                <FontAwesomeIcon icon={faGear} />
                                                            </Button>
                                                            {outcomeShow && <JsonModal remote={props.remote} show={outcomeShow} handleClose={() => handleOutcomeClose(i)} 
                                                            obj={props.values.outcome.type} name={''} facts={props.values.facts} parent={testData} />}
                                                        </>
                                                        :
                                                        <input
                                                        disabled={props.remote}
                                                            type="text"
                                                            value={props.values.testcases[i].result.value}
                                                            onChange={(e) => onOutcomeCellChange(e, i)}
                                                        />
                                                    }
                                                </td>
                                            }
                                            <td><div className={props.values.testcases[i]['status']}>{props.values.testcases[i]['status']}</div></td>
                                        </tr>
                                    );

                                })
                            }

                        </tbody>
                    </Table>

                </Row>
            </div>
        </>
    );


}

export default TestRule;