import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import helper from "../helper";

const JsonForm = (props) => {
    console.log(props);
    const obj = helper.findObject(props.facts, props.obj);
    if (props.name && props.name !== '' && props.parent){
        /*let tempEle = {}
        obj.attributes.forEach((att, i) => {
            if (helper.isObject(att.type) && att.array) {
                tempEle[att.name] = [];
            } else if (helper.isObject(att.type)) {
                tempEle[att.name] = {};
            } else {
                tempEle[att.name] = '';
            }
        })
        if (props.array){
            props.parent[props.name].push(tempEle);
        } else {
            props.parent[props.name] = tempEle;
        }*/
    } else {
        obj.attributes.forEach((att, i) => {
            if (!props.parent[att.name]) {
                if (helper.isObject(att.type) && att.array) {
                    props.parent[att.name] = [];
                } else if (helper.isObject(att.type)) {
                    props.parent[att.name] = {};
                } else {
                    props.parent[att.name] = '';
                }
            }
            
        })
    }
    console.log(props);
    
    const [attributes, setAttributes] = useState('')
    //console.log(state);

    /*if (props.name && props.name != '' && props.parent && props.parent[props.name]) {
        if (props.array) {
            props.parent[props.name] = [];
        } else {
            props.parent[props.name] = {};
        }

    }*/

    const handleChange = (event, key, index) => {
        if(index !== undefined){
            if (props.name && props.parent[props.name]) {
                props.parent[props.name][index][key] = event.target.value;
            } else {
                if (Array.isArray(props.parent[key])) {
                    props.parent[key][index] = event.target.value;
                } else {
                    props.parent[key] = event.target.value;
                }
                
            }
        } else {
            if (props.name && props.parent[props.name]) {
                props.parent[props.name][key] = event.target.value;
            } else {
                props.parent[key] = event.target.value;
            }
        }
        
    }
    const handleBoolChange = (event, key) => {
        if (props.name && props.parent[props.name]) {
            props.parent[props.name][key] = props.parent[props.name][key] ? false : true;
        } else {
            props.parent[key] = props.parent[key] ? false : true;
        }
    }
    const addElement = (name, objtype) => {
        const obj = helper.findObject(props.facts, objtype);
        let newEle = {}
        if (obj) {
            obj.attributes.forEach((att, i) => {
                if (helper.isObject(att.type) && att.array) {
                    newEle[att.name] = [];
                } else if (helper.isObject(att.type)) {
                    newEle[att.name] = {};
                } else {
                    newEle[att.name] = '';
                }
            })
        } else {
            newEle = '';
        }
        
        let temp = attributes;
        let newList = [...props.parent[name], newEle]
        props.parent[name] = newList;
        console.log(props.parent)
        temp = newList;
        setAttributes(temp);
    }
    return (
        <>
            {
                obj && obj.attributes.map((att, i) => {
                    return (
                        <>
                            <Row>
                                <Col sm={2}>{helper.camelToSentenceCase(att.name)} :
                                    {
                                        att.array &&
                                        <Button variant="link" onClick={() => addElement(att.name, att.type)}>
                                            <FontAwesomeIcon icon={faPlus} />
                                            
                                        </Button>
                                    }

                                </Col>
                                <Col sm={10}>
                                    
                                    
                                    {
                                        att.array && helper.isObject(att.type) && props.parent[att.name] &&
                                        props.parent[att.name].map((p,j) => {
                                            return (
                                                <JsonForm obj={att.type} index={j} array={att.array} name={att.name} facts={props.facts} parent={props.parent} />
                                            )
                                        })
                                    }
                                    {
                                        !att.array && helper.isObject(att.type)
                                            && <JsonForm obj={att.type} name={att.name} facts={props.facts} parent={props.parent} />
                                            
                                    }
                                    {
                                        !helper.isObject(att.type) && att.type === 'Boolean' && 
                                        <Form.Check type="checkbox"  onChange={(e) => handleBoolChange(e, att.name)} />
                                    }
                                    {
                                        att.array && !helper.isObject(att.type) && att.type !== 'Boolean' && props.parent[att.name] && 
                                        props.parent[att.name].map((p,j) => {
                                            return (
                                                <Form.Control type="text"  onChange={(e) => handleChange(e, att.name, j)} />
                                                
                                            )
                                        })
                                        
                                    }
                                    {
                                        !att.array && !helper.isObject(att.type) && att.type !== 'Boolean' && 
                                        <Form.Control type="text"  onChange={(e) => handleChange(e, att.name, props.index)} />
                                    }
                                </Col>
                            </Row>
                        </>
                    );
                })
            }
        </>
    );

};
export default JsonForm;