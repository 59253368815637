import React, { useState } from "react";
import { Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import SelectField from "./forms/select-field";
import Form from 'react-bootstrap/Form';

const AttributeForm = (props) => {
    const [attributes, setAttributes] = useState([]);

    const onAttributeNameChange = (event, index) => {
        props.parent.attributes.filter((_, i) => index === i).forEach(e => e.name = event.target.value)
        setAttributes([]);
    }
    const onAttributeArrayChange = (event, index) => {
        props.parent.attributes.filter((_, i) => index === i).forEach(e => e.array = e.array? false:true);
        setAttributes([]);
    }

    const onAttributeTypeChange = (event, index) => {
        props.parent.attributes.filter((_, i) => index === i).forEach(e => e.type = event.target.value);
        setAttributes([]);
    }

    const deleteItem = index => () => {
        props.parent.attributes = props.parent.attributes.filter((_, i) => index !== i);
        setAttributes(props.parent.attributes);
    }
    const attributesTypes = [...props.types].filter(e => e !== props.parent.name);
    return (
        <>
            {props.parent.attributes.length > 0 && <Row className="mt-3"><Col sm={1} /><Col sm={2}>Attributes:</Col></Row>}
            {props.parent.attributes.map((a, j) => {
                return (
                    <div key={`${props.id}.${props.index}-${j}`}>
                        <Row>
                            <Col sm={1}></Col>
                            
                            <Col sm={1}>{j+1}.</Col>
                            <Col sm={4}>
                                <Form.Control type="text" name={`${props.id}[${j}].name`} placeholder={`Attr Name`} 
                                disabled={props.remote}
                                value={a.name} size="sm" onChange={(event) => onAttributeNameChange(event, j)}/>
                                
                            </Col>

                            <Col sm={3}>
                                <SelectField name={`${props.id}[${j}].type`} options={attributesTypes} value={a.type}
                                remote={props.remote}
                                handleOnChange={(event) => onAttributeTypeChange(event, j)}/>
                            </Col>
                            <Col sm={2}>
                                        <Form.Check
                                        disabled={props.remote}
                                            type="checkbox"
                                            name={`${props.id}[${j}].isarray`}
                                            label="Array?"
                                            onChange={(event) => onAttributeArrayChange(event, j)}
                                        />
                                    </Col>
                                    <Col sm={1}>
                                <ButtonGroup>
                                    <Button variant="link" onClick={deleteItem(j)} disabled={props.remote}>
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </div>)

            })}
            {<hr/>}
        </>
    )
}

export default AttributeForm;