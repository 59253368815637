import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import { Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import dataTypes from '../../data/operator.json';
import SelectField from "../forms/select-field";


const OutcomesForm = (props) => {
    const [outcome, setOutcome] = useState(props.values.outcome || {name:'', array:false, 'type':''})
    
    
    const onNameChange = (event) => {
        let temp = {...outcome}
        temp.name = event.target.value;
        props.values.outcome.name = event.target.value;
        setOutcome(temp);
    }

    const onArrayChange = () => {
        let temp = {...outcome}
        temp.array = temp.array ? false : true;
        props.values.outcome.array = props.values.outcome.array ? false : true;
        setOutcome(temp);
    }

    const onTypeChange = (event) => {
        let temp = {...outcome}
        temp.type = event.target.value;
        props.values.outcome.type = event.target.value;
        setOutcome(temp);
    }

    const deleteOutcome = () => {
        props.values.outcome = {};
        setOutcome({});
    }

    const attributesTypes = Object.keys(dataTypes);
    const objectFacts = props.values.facts.filter((e) => e.type === 'Object' && e.name !== '');
    if (objectFacts.length > 0) {
        objectFacts.forEach(e => attributesTypes.push(e.name));
    }

    return (
        <div style={{ position: 'relative', padding: '12px', paddingLeft: '12px' }} className="border">
            <Row className="border-bottom border-dark">
                <Col sm={12}>{props.title}</Col>
            </Row>
            <div style={{ paddingTop: '12px' }}>

                <Row>
                    <Col sm={4}>
                        <Form.Control type="text" disabled={props.remote} name={`${props.id}.name`} value={props.values.outcome.name}
                        placeholder={`Outcome Name`} size="sm" onChange={(event) => onNameChange(event)} />
                    </Col>
                    <Col sm={4}>
                        <SelectField name={`${props.id}.type`} 
                        remote={props.remote}
                        value={props.values.outcome.type}
                        options={attributesTypes} handleOnChange={(event) => onTypeChange(event)}/>
                    </Col>
                    <Col sm={2}>
                                        <Form.Check
                                        disabled={props.remote}
                                            type="checkbox"
                                            name={`${props.id}.isarray`}
                                            label="Array?"
                                            onChange={onArrayChange}
                                        />
                                    </Col>
                    <Col sm={1}>
                        <ButtonGroup>
                            <Button variant="link" onClick={deleteOutcome} disabled={props.remote}>
                                <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
                
            </div>
        </div>
    )
}

export default OutcomesForm;